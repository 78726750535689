import { Flex } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import EditableTable from "components/RFQ/EditableTable/EditableTable";
import { FieldForm, Input } from "components/RFQ/Input";
import {
	CONTINGENCY,
	OTHER,
	PRELIMINARIES,
	PROFESSIONALFEES,
	PROVISIONALSUMS,
	TRADEPACKAGE,
} from "constants/rfq";
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { defaultOptions } from "./tableDefaultOptions";
import { CustomModal } from "components/RFQ/Modal/CustomModal";
import { Button } from "components/RFQ/Button";

const quoteTypeOptions = [
	{
		label: PRELIMINARIES,
		field: { name: PRELIMINARIES, description: "" },
		placeholder: "Project Prelims",
		value: PRELIMINARIES,
	},
	{
		label: TRADEPACKAGE,
		field: { name: TRADEPACKAGE, description: "" },
		placeholder:
			"Concrete - Supply and Install including repairs and supervision",
		value: TRADEPACKAGE,
	},
	{
		label: CONTINGENCY,
		field: { name: CONTINGENCY, description: "" },
		placeholder: CONTINGENCY,
		value: CONTINGENCY,
	},
	{
		label: PROFESSIONALFEES,
		field: { name: PROFESSIONALFEES, description: "" },
		placeholder: "Lead Design Architect Fees for Stage 1",
		value: PROFESSIONALFEES,
	},
	{
		label: PROVISIONALSUMS,
		field: { name: PROVISIONALSUMS, description: "" },
		placeholder: PROVISIONALSUMS,
		value: PROVISIONALSUMS,
	},
	{
		label: OTHER,
		field: { name: OTHER, description: "" },
		placeholder: "Hourly Rates for additional Services",
		value: OTHER,
	},
];

const ChangeQuoteTypeConfirmDialog = ({
	handleOnQuoteTypeChange,
	setIsModalOpen,
	isModalOpen,
}) => {
	const FooterComponent = () => {
		return (
			<div className="flex w-full justify-between gap-2">
				<Button
					btntype="plain-border"
					onClick={() => setIsModalOpen(false)}
					className="!w-full"
				>
					Cancel
				</Button>
				<Button
					className="flex !w-full gap-3"
					onClick={handleOnQuoteTypeChange}
					data-testid="confirm-change-quote-type"
				>
					Yes
				</Button>
			</div>
		);
	};

	return (
		<CustomModal
			isOpen={isModalOpen}
			onClose={() => setIsModalOpen(false)}
			header={{
				bgColor: "bg-brand-secondary",
				title: "Are you sure you want to change the quote type?",
				description:
					"This action will result in your current quote data being lost.",
			}}
			footerComponent={<FooterComponent />}
		/>
	);
};

export const QuoteContent = ({
	initialDataItem,
	tableVariable,
	index,
	unitMeasures,
	disable,
	isPreview = false,
	type,
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [isEditingDesc, setIsEditingDesc] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [changedQuoteData, setChangedQuoteData] = useState(null);
	const [isSectionNameFilled, setIsSectionNameFilled] = useState(false);

	const fieldname = (name) => `quote[${index}][${name}]`;
	const [, { error: itemsError, value: items }] = useField(fieldname("items"));
	const [, { value: name }, { setTouched: touchedName, setValue: setName }] =
		useField(fieldname("name"));
	const [
		,
		{ error: descriptionError },
		{ setTouched: touchedDescription, setValue: setDescription },
	] = useField(fieldname("description"));
	const [isUnitOfMeasurementError, setIsUniteOfMeasurementError] =
		useState(false);
	const data = initialDataItem;

	const [, { value: rowData }, { setValue: setRowData }] = useField(
		fieldname("items")
	);

	const addRow = (name) => {
		setRowData([
			...rowData,
			{
				id: null,
				name: name,
				unit_id: "",
				sort_order: rowData?.length + 1,
				isRemove: 0,
			},
		]);
	};

	const copyRow = (row, index) => {
		setRowData([
			...rowData,
			{
				id: null,
				placeholder: row.placeholder,
				name: null,
				unit_id: row.unit_id,
				sort_order: rowData?.length + 1,
				isRemove: row.isRemove,
			},
		]);
	};

	const removeRow = (row, index) => {
		if (row.id) {
			setRowData(
				rowData.map((item) => {
					if (item.id === row.id) {
						return { ...item, isRemove: 1 };
					}
					return item;
				})
			);
		} else {
			setRowData(rowData.filter((_, i) => index !== i));
		}
	};

	const handleChangeType = (e) => {
		setIsEditing(false);
		setIsEditingDesc(false);
		setName(e.field.name);
		setDescription(e.field.description);
		setRowData(defaultOptions(e.field.name));
		setIsModalOpen(false);
	};

	const handleQuoteChange = (e) => {
		setIsSectionNameFilled(true);
		setIsModalOpen(true);
		setChangedQuoteData(e);
	};

	useEffect(() => {
		if (itemsError) {
			let tempUnitOfMeasurementError = false;
			for (let a = 0; a < itemsError?.length; a++) {
				if (itemsError[a]?.unit_id) {
					setIsUniteOfMeasurementError(true);
					break;
				} else {
					setIsUniteOfMeasurementError(tempUnitOfMeasurementError);
				}
			}
		}
		setIsSectionNameFilled(name !== "");
	}, [itemsError, name]);

	const isSubmission = type === "submission";
	const isCustomName = (name) =>
		!quoteTypeOptions.some((option) => option.value === name) ||
		name === "Other";

	const subTotal = items?.reduce((a, b) => {
		return a + (b?.rate * b?.total || 0);
	}, 0);
	const GST = subTotal * 0.1;
	const includeGST = subTotal + GST;
	const currencyFormat = (number) =>
		(number || 0).toLocaleString("en-US", {
			style: "currency",
			currency: "USD",
		});

	const ErrorMessageComp = ({ text }) => {
		return <div className="text-md mt-2 text-red-500">{text}</div>;
	};

	const getPlaceHolder = (name) => {
		let placeholder = quoteTypeOptions.find(
			(option) => option.label === name
		)?.placeholder;
		if (placeholder) {
			return placeholder;
		} else {
			return "";
		}
	};

	return (
		<div>
			<Flex justifyContent={"space-between"}>
				<div
					id="card-header"
					className="w-2/3"
				>
					<div className="flex items-center gap-3">
						{isEditing && !isSubmission ? (
							<FieldForm
								name={fieldname("name")}
								shouldShowError={false}
								classNameBoxInput="bg-black"
								size="sm"
								type="text"
								className={`w-2/4`}
								onKeyUp={(e) => {
									e.target.value.length > 0
										? setIsSectionNameFilled(true)
										: setIsSectionNameFilled(false);
									if (e.key === "Enter") {
										e.preventDefault();
										setIsEditing(false);
										setName(e.target.value);
										touchedName(true, true);
									}
								}}
							/>
						) : (
							<h2
								onClick={() => setIsEditing(!isEditing)}
								className="font-roboto text-lg font-semibold text-primary-900"
							>
								{data?.name ? data?.name : "Section name"}
							</h2>
						)}
						{!isSubmission && isCustomName(data.name) && (
							<Icon
								icon={isEditing ? "check" : "edit-icon"}
								onClick={() => setIsEditing(!isEditing)}
								className="cursor-pointer"
								style={!isEditing ? { width: "14px", height: "14px" } : null}
							/>
						)}
						{!isSectionNameFilled && !isPreview ? (
							<div className="ml-2 text-xs text-red-500">
								Section name is required
							</div>
						) : null}
					</div>

					<div className="flex items-center gap-3">
						{isEditingDesc && !isSubmission ? (
							<FieldForm
								name={fieldname("description")}
								classNameBoxInput="bg-black"
								shouldShowError={false}
								size="sm"
								placeholder={getPlaceHolder(data?.name)}
								className={`w-2/4`}
								onKeyUp={(e) => {
									if (e.key === "Enter") {
										e.preventDefault();
										setIsEditingDesc(false);
										setDescription(e.target.value);
										touchedDescription(true, true);
									}
								}}
							/>
						) : (
							<p onClick={() => setIsEditingDesc(!isEditingDesc)} data-testid={`${fieldname('description')}click`}>
								{data?.description ? data?.description : "Section description"}
							</p>
						)}
						{!isSubmission && (
							<Icon
								icon={isEditingDesc ? "check" : "edit-icon"}
								onClick={() => setIsEditingDesc(!isEditingDesc)}
								className="cursor-pointer"
								style={
									!isEditingDesc ? { width: "14px", height: "14px" } : null
								}
								data-testid={`${fieldname('submit-desc')}`}
							/>
						)}
						{descriptionError ? (
							<div className="ml-2 text-xs text-red-500">
								Section description is required
							</div>
						) : null}
					</div>
					{typeof itemsError === "string" ? (
						<ErrorMessageComp text="Please ensure you've added at least one row" />
					) : itemsError?.length ? (
						isUnitOfMeasurementError ? (
							<ErrorMessageComp text="Please ensure you've select unit of measurement for each row" />
						) : (
							<ErrorMessageComp text="Please ensure you've added a description of scope for each row" />
						)
					) : null}
				</div>
				<div style={{ width: "368px" }}>
					{!isPreview && (
						<Input
							type="select"
							name="section"
							placeholder="Select quote type"
							options={quoteTypeOptions}
							checkIcon={true}
							icon="quote-type"
							onChange={handleQuoteChange}
							value={isCustomName(data.name) ? "Other" : data.name}
							testId={`${fieldname('select')}`}
						/>
					)}
				</div>
			</Flex>
			<div className="w-[calc(100% + 88px)] -mx-[44px] overflow-hidden font-roboto">
				<EditableTable
					unitMeasures={unitMeasures}
					data={initialDataItem.items}
					tableVariable={tableVariable}
					index={index}
					isPreview={isPreview}
					type={type}
					disable={disable}
					removeRow={removeRow}
					addRow={addRow}
					copyRow={copyRow}
				>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td className="border border-gray-300 bg-bg-gray-200 px-4 py-3 text-xs font-medium text-tertiary-600">
							Subtotal
						</td>
						<td className="border border-gray-300 px-4 py-3">
							{isSubmission ? currencyFormat(subTotal) : "$0.00"}
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td className="border border-gray-300 bg-bg-gray-200 px-4 py-3 text-xs font-medium text-tertiary-600">
							GST (10%)
						</td>
						<td className="border border-gray-300 px-4 py-3">
							{isSubmission ? currencyFormat(GST) : "$0.00"}
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td className="border border-gray-300 bg-bg-gray-200 px-4 py-3 text-xs font-medium text-tertiary-600">
							Total Cost
						</td>
						<td className="border border-gray-300 px-4 py-3">
							{isSubmission ? currencyFormat(includeGST) : "$0.00"}
						</td>
					</tr>
				</EditableTable>
			</div>
			<ChangeQuoteTypeConfirmDialog
				isModalOpen={isModalOpen}
				handleOnQuoteTypeChange={() => handleChangeType(changedQuoteData)}
				setIsModalOpen={setIsModalOpen}
			/>
		</div>
	);
};
