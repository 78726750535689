import React, { useEffect, useState, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import axios from "axios";
import { Heading } from "../../components/Heading";
import { CompanyCard } from "../../components/CompanyCard";
import { getCompanies, getCompaniesByPage } from "../../actions/companyActions";
import { Button } from "components/Button";
import "styles/loading.scss";
import { TabContent } from "../../components/Tabs/styled";
import { Icon } from "assets/icons/Icon";
import CreateSupplierDrawer from "./drawer/CreateSupplierDrawer";
import AddSupplierDrawer from "./drawer/AddSupplierDrawer";
import { isAdmin } from "utils/helpers";
import { Flex, SkeletonCircle } from "@chakra-ui/react";
import { SearchBox } from "components/SearchBox";
import { Toolbar } from "components/Toolbar";
import { Input } from "components/RFQ/Input";
import { removeFromList } from "../../actions/searchActions";
import { SkeletonBase } from "components/Skeleton";
import { Grid, GridItem } from "@chakra-ui/react";
import { Pagination } from "components/Pagination";
import { useDispatch } from "react-redux";

const ShortlistStyled = styled.div`
	padding: ${(props) =>
		props.padded ? `${props.theme.space.lg} ${props.theme.space.md}` : 0};

	${(props) =>
		props.isInSearchShortlist &&
		`
        margin: 0;
    `}

	.shortlisted-text {
		font-size: ${(props) => props.theme.fontSizes.xxl};
		line-height: 32px;
		margin-bottom: 0px;
	}
`;

const ShortlistContainer = ({
	getCompanies,
	items,
	noHeading,
	padded,
	isInSearchShortlist = false,
	handleRemoveButtonClick,
	linkNewTab,
	user,
	removeFromList,
	...props
}) => {
	const [showCreateDrawer, setShowCreateDrawer] = useState(false);
	const [showAddDrawer, setShowAddDrawer] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [idSupplierList, setIdSupplierList] = useState("");
	const [sortBy, setSortBy] = useState("name");
	const [keyword, setKeyword] = useState("");
	const search = useSelector((state) => state.search);
	const company = useSelector((state) => state?.auth?.current_company);
	const dispatch = useDispatch();

	const companies = isInSearchShortlist
		? search.shortlist
				.filter((company) => {
					if (!keyword) return true;
					const searchTerm = keyword.toLowerCase();
					return (
						company.name?.toLowerCase().includes(searchTerm) ||
						company.company_number?.toLowerCase().includes(searchTerm) ||
						company.description?.toLowerCase().includes(searchTerm)
					);
				})
				.sort((a, b) => {
					if (sortBy === "name") {
						return a.name.localeCompare(b.name);
					} else if (sortBy === "-name") {
						return b.name.localeCompare(a.name);
					}
					return 0;
				})
		: items.length
			? items
			: search?.searchResult;

	const sortList = [
		{
			value: "name",
			label: "Sort Alphabetically (A-Z)",
		},
		{
			value: "-name",
			label: "Sort Alphabetically (Z-A)",
		},
	];

	// Calculate pagination -- it's not using Meta. // Todo: need to use Meta
	const totalPages = search?.paginationMeta?.total;

	useEffect(() => {
		let url = new URL(window.location.href);
		let params = new URLSearchParams(url.search);
		let supplier = params.get("supplier");
		if (supplier) {
			setIdSupplierList(supplier);
		}
	}, []);

	const fetchCompanies = useCallback(async () => {
		setIsLoading(true);
		try {
			getCompanies(keyword, sortBy);
		} finally {
			setIsLoading(false);
		}
	}, [getCompanies, keyword, sortBy]);

	useEffect(() => {
		if (!items.length && !isInSearchShortlist) {
			fetchCompanies();
		}
	}, [items, isInSearchShortlist, fetchCompanies]);

	const handlePageChange = (page) => {
		if (page >= 1 && page <= totalPages) {
			dispatch(getCompaniesByPage(page));
			const companyList = document.querySelector(".company-list");
			companyList.scrollIntoView({ top: 0, behavior: "smooth" });
		}
	};

	let listIDCompanies = [];
	let listCompanies = [];
	for (let a = 0; a < companies?.length; a++) {
		listIDCompanies.push(companies[a].id);
		listCompanies.push({
			label: companies[a]?.name,
			value: companies[a]?.id,
		});
	}
	const groupId = props.groupId;
	const companyId = props.companyId;

	const download = () => {
		setIsLoading(true);
		axios({
			url:
				process.env.REACT_APP_API_URL +
				`/groups/print-pdf/${groupId}?companyId=${companyId}`,
			method: "GET",
			responseType: "blob", // important
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "shortlist.pdf");
			document.body.appendChild(link);
			link.click();
			setIsLoading(false);
		});
	};

	const createSupplierList = () => {
		setShowCreateDrawer(true);
	};

	const addSupplierToList = () => {
		setShowAddDrawer(true);
	};

	const onCloseCreateDrawer = () => {
		setShowCreateDrawer(false);
	};

	const onCloseAddDrawer = () => {
		setShowAddDrawer(false);
	};

	const onSearchSubmit = async (values) => {
		const cleanKeyword = values?.keyword?.trim() || "";
		setKeyword(cleanKeyword);

		if (!isInSearchShortlist) {
			await getCompanies(values?.keyword, sortBy);
		}
	};

	const changeSortBy = (select) => {
		setSortBy(select?.value);

		if (!isInSearchShortlist) {
			getCompanies(keyword, select?.value);
		}
	};
	const handleRemove = (companyId) => {
		removeFromList(companyId, "shortlist", groupId);
	};

	const CompanyGrid = () => {
		if (search?.isLoading) {
			return (
				<Grid
					templateColumns="repeat(3, 1fr)"
					gap={6}
					columnGap={6}
					className="w-full"
				>
					{[...Array(6)].map((_, index) => (
						<GridItem
							key={index}
							className="relative bg-white"
						>
							<SkeletonBase
								width="100%"
								startColor="#c8ccd4"
								height="180px"
								className="border border-white"
							/>
							<SkeletonCircle
								size="140px"
								startColor="#c8ccd4"
								className="z-1 absolute left-1/2 -translate-x-1/2 -translate-y-1/2 border border-white"
							/>
							<SkeletonBase
								width="50%"
								startColor="#c8ccd4"
								height="16px"
								className="mx-auto mt-[100px]"
							/>
							<SkeletonBase
								width="50%"
								startColor="#c8ccd4"
								height="10px"
								className="mx-auto my-2"
							/>
						</GridItem>
					))}
				</Grid>
			);
		}

		return (
			<div className="grid grid-cols-1 justify-between gap-4 lg:grid-cols-2 xl:grid-cols-3">
				{companies.length ? (
					companies.map((item) => {
						return (
							<CompanyCard
								key={item.id}
								id={item.id}
								name={item.name}
								size={item.size}
								logo={item.logo_path}
								banner={item.banner_path}
								className={`col md-6 ${isInSearchShortlist ? "lg-3" : "lg-4"}`}
								admin={props.admin}
								location={item.location}
								profileType={item.profile_type}
								handleRemoveButtonClick={
									isInSearchShortlist ? handleRemove : null
								}
								linkNewTab={linkNewTab}
								company_profile_status={item.company_profile_status}
								isAdmin={props.admin}
								isSearchShortlist={isInSearchShortlist}
							/>
						);
					})
				) : (
					<div className="col-span-full text-center">No Companies Found</div>
				)}
			</div>
		);
	};

	return (
		<div className="w-full">
			<TabContent>
				{!noHeading && (
					<Heading size="xl">
						{props.admin ? "Companies Admin" : "Companies Listings"}
					</Heading>
				)}
				<Flex className="!w-full justify-end">
					<div>
						<Toolbar>
							<SearchBox
								placeholder="Search resources"
								handleFormSubmit={onSearchSubmit}
								keywordFilter
								whiteInput
								isCompanyList
							/>
							<div style={{ marginLeft: "20px" }}>
								<Input
									options={sortList}
									onChange={changeSortBy}
									placeholder={sortBy}
									value={sortList[0].value}
									type="select"
									className="!max-h-[50px] !w-[250px]"
									size="base"
								/>
							</div>
						</Toolbar>
					</div>
				</Flex>
				<ShortlistStyled
					className="row company-list w-full"
					padded={padded}
					isInSearchShortlist={isInSearchShortlist}
				>
					{<CompanyGrid />}
					{!window.location.pathname.includes("shortlist") &&
						search?.searchResult?.length > 0 && (
							<Pagination
								currentPage={search?.paginationMeta?.current_page}
								totalItems={search?.paginationMeta?.total}
								itemsPerPage={search?.paginationMeta?.per_page}
								onPageChange={handlePageChange}
							/>
						)}

					{companies?.length > 0 &&
						!window.location.pathname.includes("accounts") &&
						!window.location.pathname.includes("companies") && (
							<div className="mt-8">
								<p className="font-bold">
									Download a detailed comparison of your shortlisted suppliers.
								</p>
								<div className="mt-8 flex pt-2">
									<Button
										btntype="no-background-border"
										height="46px"
										className="mr-4 !p-0"
										onClick={download}
										disabled={isLoading}
									>
										{!isLoading ? (
											<>
												<span className="iconInline">
													<Icon icon="download" />
												</span>
												<div className="ml-2" data-testid="download-shortlist">DOWNLOAD SHORTLIST</div>
											</>
										) : (
											<>Downloading</>
										)}
									</Button>
									{company?.enable_rfx_functionality && (
										<>
											<Button
												btntype="blueBorder"
												height="46px"
												className="mr-3"
												onClick={() => addSupplierToList()}
												data-testid="add-to-existing-supplier-list"
											>
												Add to Existing Supplier list
											</Button>
											<Button
												btntype="primary"
												height="46px"
												onClick={() => createSupplierList()}
												data-testid="add-to-new-supplier-list"
											>
												Add to New Supplier list
											</Button>
										</>
									)}
								</div>
							</div>
						)}
				</ShortlistStyled>
			</TabContent>
			<CreateSupplierDrawer
				isOpen={showCreateDrawer}
				onClose={onCloseCreateDrawer}
				listIDCompanies={listIDCompanies}
			></CreateSupplierDrawer>
			<AddSupplierDrawer
				isOpen={showAddDrawer}
				onClose={onCloseAddDrawer}
				listCompanies={listCompanies}
				activeSupplierList={idSupplierList}
				isAdmin={isAdmin(user)}
			></AddSupplierDrawer>
		</div>
	);
};

ShortlistContainer.defaultProps = {
	items: [],
};

const mapStateToProps = (state) => {
	return {
		searchResult: state.search.searchResult,
		isSearching: state.search.isLoading,
		groupId: state.search.groupId,
		companyId: state.auth.current_company.id,
		user: state.auth.user,
	};
};

const mapDispatchToProps = {
	getCompanies,
	removeFromList,
};

export const Shortlist = connect(
	mapStateToProps,
	mapDispatchToProps
)(ShortlistContainer);
