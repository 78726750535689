import React, { useState } from "react";
import { useField } from "formik";
import { TextAreaStyled, TextAreaContainer, WordCountStyled } from "./styled";
import { Error } from "../Error";
import { ReactComponent as InfoIcon } from "../../../icons/info.svg";
import ReactTooltip from "react-tooltip";
import { cn } from "utils/helpers";
import LabelForm from "components/RFQ/LabelForm/LabelForm";

export const TextArea = ({
	label,
	required,
	name,
	errorBottom,
	characterLimit,
	guideLine,
	className,
	rfq,
	testId,
	hint,
	hideError,
	...props
}) => {
	const [field, meta, helpers] = useField(name);
	const [characterCount, setCharacterCount] = useState(
		field.value?.toString().length
	);

	const onChange = (e) => {
		const newVal = e.currentTarget.value;
		helpers.setValue(newVal);
		setCharacterCount(newVal.toString().length);
	};

	const textArea = (
		<TextAreaStyled
			data-testid={testId}
			className={cn("textarea", rfq && `focusable !rounded-md`)}
			value={field.value}
			maxLength={characterLimit}
			onChange={onChange}
			{...props}
		/>
	);

	return (
		<div className={cn(className, "relative")} >
			{label && (
				<LabelForm
					htmlFor={props.id || props.name}
					label={label}
					required={required}
					style={{ marginBottom: "6px" }}
				>
					<>
						{guideLine && (
							<InfoIcon
								data-tip
								data-for={name}
							/>
						)}
						<ReactTooltip
							className="guide"
							id={name}
							place="top"
							arrowColor="transparent"
						>
							{guideLine}
						</ReactTooltip>
					</>
				</LabelForm>
			)}
			<p className="-mt-2 mb-2 text-sm text-gray-500">{hint}</p>

			{characterLimit ? (
				<TextAreaContainer
					isError={meta.error && meta.touched}
					className={
						rfq &&
						`!rounded-md focus-within:border-utility-brand-300 focus-within:ring-4 ${meta.error && meta.touched ? "ring-ring-red-primary" : "ring-ring-blue-primary"}`
					}
				>
					{textArea}
					<WordCountStyled>
						{characterLimit - characterCount} character
						{characterLimit - characterCount === 1 && "s"} remaining
					</WordCountStyled>
				</TextAreaContainer>
			) : (
				textArea
			)}

			{!hideError && (
				<Error
					errorBottom={errorBottom}
					meta={meta}
					className={rfq && "-mt-5 !text-sm !text-utility-error-500"}
				/>
			)}
		</div>
	);
};
