import { useEffect, useState } from "react";

import { Flex } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { Input, FieldForm } from "components/RFQ/Input";
import { useField } from "formik";
import { Button } from "components/Button";
import QuestionTypeTable from "components/Pages/QuestionType/QuestionTypeTable";

export const QUESTION_TYPES = {
	SHORT_ANSWER: 1,
	PARAGRAPH: 2,
	SINGLE_OPTION: 3,
	MULTIPLE_CHOICE: 4,
	DROP_DOWN: 5,
	DATE_SELECTION: 6,
	ADDRESS_FINDER: 7,
	PROJECT_EXPERIENCE: 8,
	KEY_PERSONNEL: 9,
	CORPORATE_DOCUMENTS: 10,
	FILE_UPLOAD: 11,
	TABLE: 12,
};

const questionsOptions = [
	{
		value: QUESTION_TYPES.SHORT_ANSWER,
		label: "Short Answer",
		icon: "short-answer-icon",
	},
	{
		value: QUESTION_TYPES.PARAGRAPH,
		label: "Paragraph",
		icon: "paragraph-icon",
	},
	{
		value: QUESTION_TYPES.SINGLE_OPTION,
		label: "Single Option",
		icon: "single-option-icon",
	},
	{
		value: QUESTION_TYPES.MULTIPLE_CHOICE,
		label: "Multiple Choice",
		icon: "multiple-choice-icon",
	},
	{
		value: QUESTION_TYPES.DROP_DOWN,
		label: "Drop-down",
		icon: "dropdown-icon",
	},
	{
		value: QUESTION_TYPES.TABLE,
		label: "Table",
		icon: "columns-icon",
	},
	{
		value: QUESTION_TYPES.DATE_SELECTION,
		label: "Date Selection",
		icon: "date-selection-icon",
	},
	{
		value: QUESTION_TYPES.ADDRESS_FINDER,
		label: "Address Finder",
		icon: "address-finder-icon",
	},
	{
		value: QUESTION_TYPES.PROJECT_EXPERIENCE,
		label: "Project Experience",
		icon: "stars-icon",
	},
	{
		value: QUESTION_TYPES.KEY_PERSONNEL,
		label: "Key Personnel",
		icon: "stars-icon",
	},
	{
		value: QUESTION_TYPES.CORPORATE_DOCUMENTS,
		label: "Corporate Documents",
		icon: "stars-icon",
	},
	{
		value: QUESTION_TYPES.FILE_UPLOAD,
		label: "File Upload",
		icon: "file-upload-icon",
	},
];

export const ShortAnswerContent = () => {
	return (
		<Flex
			gap={4}
			className="my-4 text-sm font-normal"
			align={"center"}
		>
			<div>
				<p className="text-primary-900">Short-answer text</p>
			</div>
			<div>
				<p className="text-tertiary-600">Maximum of 500 characters.</p>
			</div>
		</Flex>
	);
};
export const ParagraphContent = () => {
	return (
		<Flex
			gap={4}
			className="my-4 text-sm font-normal"
			align={"center"}
		>
			<div>
				<p className="text-primary-900">Long-answer text</p>
			</div>
			<div>
				<p className="text-tertiary-600">Maximum of 1500 characters.</p>
			</div>
		</Flex>
	);
};

export const MultipleChoiceContent = ({ fieldname, type }) => {
	const [, meta, helper] = useField(`${fieldname("fields")}`);
	const isError = meta?.error && meta?.touched;

	useEffect(() => {
		if (
			!meta.value ||
			typeof meta.value === "string" ||
			meta?.value?.length === 0 ||
			typeof meta?.value[0] === "object"
		) {
			helper.setValue([""]);
		}
		//eslint-disable-next-line
	}, [meta]);

	let types;
	switch (type) {
		case QUESTION_TYPES.SINGLE_OPTION:
			types = "Option";
			break;
		case QUESTION_TYPES.MULTIPLE_CHOICE:
			types = "Checkbox";
			break;
		case QUESTION_TYPES.DROP_DOWN:
			types = "Selection";
			break;
		default:
			break;
	}

	const deleteOptionHandler = (index) => {
		if (index > 0) {
			let newQuestions = [...meta.value];
			newQuestions.splice(index, 1);
			helper.setValue(newQuestions);
		}
	};

	const addOptionHandler = () => {
		helper.setValue([...meta.value, ""]);
	};

	return (
		<div className="space-y-1.5">
			{/* {meta?.error ? (
        <div className="text-red-500 text-md my-2">Please ensure you've added at least 1 {types}</div>
      ) : null}
       -- this error handling not handle empty options name and since multiple choice is already have default 1 options */}

			{meta?.value &&
				meta?.value instanceof Array &&
				meta?.value.length > 0 &&
				meta?.value.map((item, index) => {
					return (
						<Flex
							gap={4}
							className="text-sm font-normal"
							align={isError ? "start" : "center"}
							key={`${fieldname("fields")}-${index}`}
						>
							<FieldForm
								placeholder={`${types} ${index + 1}`}
								name={`${fieldname("fields")}[${index}]`}
							/>
							{index > 0 ? (
								<Button
									type="button"
									btntype="linkDanger"
									className="inline-flex cursor-pointer items-center rounded-md"
									onClick={() => deleteOptionHandler(index)}
								>
									<Icon
										icon="close"
										className="ml-1"
									/>
								</Button>
							) : null}
						</Flex>
					);
				})}

			<Button
				type="button"
				btntype="link"
				gap={2}
				align="center"
				className="!my-4 !mb-0 inline-flex cursor-pointer"
				onClick={addOptionHandler}
				data-testid={`${fieldname('option')}`}
			>
				<Icon
					icon="plus"
					style={{ color: "#0045F5", width: "18px", height: "18px" }}
				/>
				<span className="text-sm font-medium">Add {types}</span>
			</Button>
		</div>
	);
};

export const DateSelectionContent = () => {
	return (
		<div>
			<Flex
				gap={4}
				className="my-4 text-sm font-normal"
				align={"center"}
			>
				<div>
					<p className="text-primary-900">Single-date selection</p>
				</div>
				<div>
					<p className="text-tertiary-600">
						User can select one date from the calendar
					</p>
				</div>
			</Flex>
		</div>
	);
};
export const AddressFinderContent = () => {
	return (
		<Flex
			gap={4}
			className="my-4 text-sm font-normal"
			align={"center"}
		>
			<div>
				<p className="text-primary-900">Search address input field</p>
			</div>
			<div>
				<p className="text-tertiary-600">
					User can enter and search for an address
				</p>
			</div>
		</Flex>
	);
};
export const FileUploadContent = () => {
	return (
		<Flex
			gap={4}
			className="my-4"
			align={"center"}
		>
			<div>
				<p className="text-tertiary-600">User can provide a file upload</p>
			</div>
		</Flex>
	);
};
export const SmartContent = ({ smartFieldId }) => {
	let smartFieldMessage;

	switch (smartFieldId) {
		case QUESTION_TYPES.PROJECT_EXPERIENCE:
			smartFieldMessage =
				"This feature allows respondents to select up to 3 specific projects from their profile to provide evidence of project experience as part of their submission.";
			break;
		case QUESTION_TYPES.KEY_PERSONNEL:
			smartFieldMessage =
				"This feature allows respondents to select up to 3 Key Personnel from their profile to demonstrate evidence of relevant expertise that can be applied to the project as part of their submission.";
			break;
		case QUESTION_TYPES.CORPORATE_DOCUMENTS:
			smartFieldMessage =
				"This feature allows respondents to select Corporate Documents from their profile and provide evidence as part of their submission.";
			break;
		default:
			smartFieldMessage = "Smartfield";
			break;
	}

	return (
		<Flex
			gap={4}
			className="my-4 text-sm font-normal"
			align={"center"}
		>
			<div>
				<p className="text-tertiary-600">{smartFieldMessage}</p>
			</div>
		</Flex>
	);
};

export const QuestionComponent = ({ index, sectionIndex, question }) => {
	const [questionerType, setQuestionerType] = useState(
		question.rfq_question_type_id
	);
	const fieldname = (name) =>
		`sections[${sectionIndex}]questionnaire[${index}]${name}`;
	const [, , helper] = useField(fieldname("rfq_question_type_id"));
	const [, , { setError: setCurrentQuestionFieldsError }] = useField(
		fieldname("fields")
	);

	const [, , { setValue: setCurrentQuestionName }] = useField(
		fieldname("name")
	);

	const changeHandler = (val) => {
		if (
			val.value === QUESTION_TYPES.MULTIPLE_CHOICE ||
			val.value === QUESTION_TYPES.SINGLE_OPTION ||
			val.value === QUESTION_TYPES.TABLE ||
			val.value === QUESTION_TYPES.DROP_DOWN
		) {
			setCurrentQuestionFieldsError(null);
		}
		setQuestionerType(val.value);
		helper.setValue(val.value);
		setCurrentQuestionName("");
	};

	useEffect(() => {
		setQuestionerType(question?.rfq_question_type_id);
	}, [question]);

	const renderContent = (questionerType, fieldname) => {
		switch (questionerType) {
			case QUESTION_TYPES.SHORT_ANSWER:
				return <ShortAnswerContent />;
			case QUESTION_TYPES.PARAGRAPH:
				return <ParagraphContent />;
			case QUESTION_TYPES.SINGLE_OPTION:
				return (
					<MultipleChoiceContent
						fieldname={fieldname}
						type={questionerType}
					/>
				);
			case QUESTION_TYPES.MULTIPLE_CHOICE:
				return (
					<MultipleChoiceContent
						fieldname={fieldname}
						type={questionerType}
					/>
				);
			case QUESTION_TYPES.DROP_DOWN:
				return (
					<MultipleChoiceContent
						fieldname={fieldname}
						type={questionerType}
					/>
				);
			case QUESTION_TYPES.DATE_SELECTION:
				return <DateSelectionContent />;
			case QUESTION_TYPES.ADDRESS_FINDER:
				return <AddressFinderContent />;
			case QUESTION_TYPES.PROJECT_EXPERIENCE:
				return (
					<SmartContent smartFieldId={QUESTION_TYPES.PROJECT_EXPERIENCE} />
				);
			case QUESTION_TYPES.KEY_PERSONNEL:
				return <SmartContent smartFieldId={QUESTION_TYPES.KEY_PERSONNEL} />;
			case QUESTION_TYPES.CORPORATE_DOCUMENTS:
				return (
					<SmartContent smartFieldId={QUESTION_TYPES.CORPORATE_DOCUMENTS} />
				);
			case QUESTION_TYPES.FILE_UPLOAD:
				return <FileUploadContent />;
			case QUESTION_TYPES.TABLE:
				return <QuestionTypeTable fieldName={fieldname} />;
			default:
				return;
		}
	};

	return (
		<div>
			<Flex
				direction={"column"}
				gap={5}
				align={"baseline"}
				className="mb-3"
			>
				<Input
					type="select"
					name="section"
					placeholder="Select question type"
					options={questionsOptions}
					checkIcon={true}
					isContainImg={true}
					className="w-1/3"
					required
					onChange={changeHandler}
					value={questionerType}
					testId={`sections[${sectionIndex}]questionnaire[${index}]type`}
				/>
				<FieldForm
					type="textArea"
					required
					placeholder="Add a Question/Request: E.g. Tenderers are required to prepare and attach a program for the Works."
					name={fieldname("name")}
				/>
			</Flex>
			{renderContent(questionerType, fieldname)}
		</div>
	);
};
