import {
	PrivacyPolicyLink,
	TermsAndConditionLink,
} from "components/ContextMenu/MenuTerms";
import { Button } from "components/RFQ/Button";
import { CustomModal } from "components/RFQ/Modal/CustomModal";

type SignupTermsModalPropType = {
	isModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	handleOnAccept: () => void;
	handleClose: () => void;
};

const SignupTermsModal: React.FC<SignupTermsModalPropType> = ({
	handleClose,
	handleOnAccept,
	isModalOpen,
}) => {
	return (
		<CustomModal
			isOpen={isModalOpen}
			onClose={handleClose}
			header={{
				title: `Important notice`,
				icon: `request-for-quote`,
				bgColor: "bg-utility-error-50",
				iconColor: "text-utility-error-500",
			}}
			footerComponent={
				<div className="flex w-full justify-between gap-2">
					{/* @ts-ignore */}
					<Button
						className="!w-full !normal-case"
						btntype="primary"
						onClick={handleOnAccept}
					>
						Yes
					</Button>
					{/* @ts-ignore */}
					<Button
						className="!w-full"
						btntype={"secondary"}
						onClick={handleClose}
					>
						Close
					</Button>
				</div>
			}
		>
			<span>
				This is an annual reminder that we periodically update our Terms of Use
				and Policies. To continue, please confirm that you have read and agree
				to the current{" "}
				<TermsAndConditionLink className="text-black underline" /> and{" "}
				<PrivacyPolicyLink className="text-black underline" />
			</span>
		</CustomModal>
	);
};

export default SignupTermsModal;
