import React from "react";
import tw, { styled, css } from "twin.macro";
import DropDownItem from "./DropDownItem";
import GlobalAvatarGroup from "../AvatarGroup/AvatarGroup";
import moment from "moment";
import { Link } from "@reach/router";
import { navigate } from "@reach/router";
import { getCurrentURL } from "utils/helpers";
import { useSelector } from "react-redux";

const avatars = (suppliers) =>
	suppliers.map((supplier) => {
		return {
			avatar: supplier?.logo_path,
		};
	});

const SupplierListCardStyled = styled.div(() => [
	tw`w-[100%] min-w-[280px] rounded-md relative`,
	css`
		.img-card {
			object-fit: cover;
			height: 104px;
			width: 100%;
		}

		#card-content {
			padding: 12px 24px;
			background-color: white;
			height: 350px;
			position: relative;
			display: grid;
			gap: 32px;
			border-radius: 6px;
			margin-top: -6px;
			${tw`border-t-8 border-royal-blue h-max`}
			h2 {
				font-weight: 600;
				font-size: 20px;
				color: black;
				margin-top: 4px;
			}
			.project-code {
				margin-bottom: 8px;
			}
			.title-container {
				max-width: 90%;
				min-height: 110px;
			}
		}

		#card-description {
			display: flex;
			justify-content: space-between;
			align-items: "center";
			font-size: 14px;
			padding-top: 8px;
			padding-bottom: 8px;
		}
		.border-top {
			border-top: 1px solid #eaecf0;
		}
		.bold-font {
			color: #101828;
			font-weight: 600;
		}
		.card-desc-container {
			place-self: bottom;
		}
	`,
]);

const SupplierListCard = ({
	id,
	title,
	description,
	code,
	startDate,
	endDate,
	suppliers,
	archived,
	currentTab,
	sortTestId,
}) => {
	const slug = title?.toLowerCase().replace(/ /g, "-");
	const { company_admin } = useSelector((state) => state?.auth?.user);
	let showToggleMenu = true;
	if (!company_admin && currentTab === "companyApprovedList") {
		showToggleMenu = false;
	}
	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			let fullURL = getCurrentURL();
			navigate(`${fullURL}${id}/${slug}`);
		}
	};

	return (
		<SupplierListCardStyled className={`card-shadow spl-card-${id}`}>
			{showToggleMenu && (
				<div id="header-card" sort-testid={sortTestId}>
					<div
						className="absolute flex"
						style={{ right: "0px", gap: "8px", padding: "12px", zIndex: "10" }}
						data-testid={`dropdown-menu-${id}`}
					>
						<DropDownItem
							id={id}
							noBg
							archived={archived}
							currentTab={currentTab}
						/>
					</div>
				</div>
			)}
			<Link to={`${id}/${slug}`}>
				<div
					id="card-content"
					className="focusable"
					onKeyDown={handleKeyDown}
				>
					<div className="title-container text-black">
						<h2
							className="line-clamp-1 overflow-hidden text-ellipsis font-roboto font-semibold text-primary-900"
							style={{ fontSize: "20px" }}
						>
							{title ? title : "-"}
						</h2>
						<p
							className="project-code line-clamp-1 overflow-hidden text-ellipsis font-roboto font-normal uppercase text-tertiary-600"
							style={{ fontSize: "14px", lineHeight: "20px" }}
						>
							{code}
						</p>
						<p
							className="line-clamp-2 overflow-hidden text-ellipsis font-roboto text-primary-500"
							style={{ fontSize: "14px", lineHeight: "20px" }}
						>
							{description}
						</p>
					</div>
					<div className="card-desc-container">
						<div id="card-description">
							<p
								className="font-roboto font-normal text-primary-500"
								style={{ fontSize: "14px" }}
							>
								Start Date
							</p>
							<p
								className="font-roboto font-semibold text-primary-900"
								style={{ fontSize: "14px" }}
							>
								{moment(startDate).format("DD MMMM YYYY")}
							</p>
						</div>
						<div
							id="card-description"
							className="border-top"
						>
							<p
								className="font-roboto font-normal text-primary-500"
								style={{ fontSize: "14px" }}
							>
								End Date
							</p>
							<p
								className="font-roboto font-semibold text-primary-900"
								style={{ fontSize: "14px" }}
							>
								{moment(endDate).format("DD MMMM YYYY")}{" "}
							</p>
						</div>
						<div
							id="card-description"
							className="border-top h-[48px] items-center"
						>
							<p
								className="font-roboto font-normal text-primary-500"
								style={{ fontSize: "14px" }}
							>
								Suppliers
							</p>
							<GlobalAvatarGroup
								avatarSize="sm"
								maxAvatars={2}
								avatars={avatars(suppliers)}
							/>
						</div>
					</div>
				</div>
			</Link>
		</SupplierListCardStyled>
	);
};

export default SupplierListCard;
