import { Flex, Image } from "@chakra-ui/core";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { DEFAULT_IMAGE_AVATAR } from "utils/constants";

const avatarSizes = {
	sm: tw`h-8 w-8 p-1 min-w-8 min-h-8`,
	md: tw`h-10 w-10 p-1 min-w-10 min-h-10`,
	lg: tw`h-12 w-12 p-0.5 min-w-12 min-h-12`,
};

const AvatarStyled = styled.img(({ className }) => [
	tw` h-full w-full border-[3px] border-white object-contain text-xs font-medium text-center content-center uppercase`,
	tw`${className}`,
]);
const AvatarNullStyled = styled.div((className) => [
	tw`h-10 w-10 rounded-full border-[3px] border-white  text-xs font-medium text-center content-center bg-royal-blue2 uppercase`,
	tw`${className}`,
]);

const ContainerStyled = styled(Flex)(({ noHover, size = "md" }) => [
	tw`relative rounded-full bg-white justify-center !p-2 overflow-hidden`,
	avatarSizes[size],
	!noHover && tw`hover:shadow-md`,
]);

const ImageStyled = styled(Image)(() => [
	tw`w-4 h-4 absolute border border-white rounded-full bottom-0 right-0`,
]);

const StatusStyled = styled.div(() => [
	tw`bg-utility-success-700 w-4 h-4 absolute border border-white rounded-full bottom-0 right-0`,
]);

const computeInitials = (name) => {
	return name
		.split(" ")
		.slice(0, 2)
		.map((word) => word[0])
		.join("")
		.toUpperCase();
};

const defaultImageFor = {
	user: "/img/user-01.svg",
	company: DEFAULT_IMAGE_AVATAR,
};

const Avatar = ({
	avatar,
	className,
	badge,
	status,
	alt = "initial",
	defaultImage = "company",
	noHover,
	size,
	...props
}) => {
	const initials = computeInitials(alt);

	const avatarNull = () => {
		return <AvatarNullStyled>{initials}</AvatarNullStyled>;
	};

	const renderAvatar = () => {
		const imageSrc = avatar
			? avatar
			: defaultImage
				? defaultImageFor[defaultImage]
				: avatarNull();

		return (
			<AvatarStyled
				className={`${className}`}
				src={imageSrc}
				alt={initials}
				onError={(e) => {
					e.target.src = defaultImageFor[defaultImage];
				}}
				{...props}
			/>
		);
	};

	return (
		<ContainerStyled noHover={noHover}>
			{renderAvatar()}
			{badge && (
				<ImageStyled
					src={badge}
					alt="badge"
				/>
			)}
			{status && <StatusStyled />}
		</ContainerStyled>
	);
};

export default Avatar;
