import { SortItemButton, DropdownMenuItem } from "components/SortItemButton";
import React from "react";

const SortComp = ({ sorted, onClickCB, openPopUpCB, setOpenPopUpCB, data }) => {
	return (
		<>
			<SortItemButton
				value={sorted.sort}
				openPopUp={openPopUpCB}
				setOpenPopUp={setOpenPopUpCB}
				data-testid="sort-button"
			>
				{data.map((item, index) => (
					<DropdownMenuItem
						key={index}
						onClick={() => {
							onClickCB(item);
						}}
						className="p-0"
					>
						<div className="flex h-10 items-center" data-testid={`sort-item-${item.value}`}>
							<div className="my-auto font-roboto text-sm font-medium capitalize text-secondary-700">
								{item.name}
							</div>
						</div>
					</DropdownMenuItem>
				))}
			</SortItemButton>
		</>
	);
};
export default SortComp;
