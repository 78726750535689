import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Heading } from "../Heading";
import { TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";
import { connect } from "react-redux";
import { logoutGuestUser } from "../../actions/authActions";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { BackButton } from "../BackButton/BackButton";
import { navigate } from "@reach/router";
import { Checkbox2 } from "../FormInputs/Checkbox2";
import { SignupSection, BottomLinks } from "./styled";
import { FlashError } from "../FormInputs/FlashError";
import { signup } from "../../actions/authActions";
import { getPasswordStrength } from "../../utils/helpers";
import { PasswordValidator } from "../FormInputs/PasswordValidator";
import { useLocation } from "@reach/router";
import SignupTermsModal from "./SignupTermsModal";
import {
	PrivacyPolicyLink,
	TermsAndConditionLink,
} from "components/ContextMenu/MenuTerms";

const CustomLabel = () => {
	return (
		<p>
			I agree to the <TermsAndConditionLink />
			and <PrivacyPolicyLink />
		</p>
	);
};

export const SignUpFormComponent = ({ logoutGuestUser, ...props }) => {
	const user_id = props.user.id ? props.user.id : null;
	const [resultError, setresultError] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const location = useLocation();
	const email = location.state?.email;
	const [passwordRules, setPasswordRules] = useState({
		letter: false,
		capital: false,
		number: false,
		count: false,
		specialCharacter: false,
	});

	const tooltips = {
		first_name:
			"Please check your 'First Name'. It should be 1-50 letters long, can include spaces, hyphens, or apostrophes, and shouldn't start or end with a space. Need help? Email us at support@procuracon.com.au",
		last_name:
			"Please check your 'Last Name'. It should be 1-50 letters long, can include spaces, hyphens, or apostrophes, and shouldn't start or end with a space. Need help? Email us at support@procuracon.com.au",
		email:
			"Please check your 'Email Address'.\n It should follow the standard email format (like example@example.com) & can't be more than 190 characters. Must be unique (not already registered with us). Need help? Email us at support@procuracon.com.au",
		password:
			" Please ensure password contains the necessary characters, length, and complexity as specified. If you need help, feel free to email us at support@procuracon.com.au",
	};

	useEffect(() => {
		async function logoutGuest() {
			await logoutGuestUser();
			navigate("/signup-procuracon", {
				state: {
					email: email,
				},
			});
		}
		logoutGuest();
	}, [logoutGuestUser, email]);

	const handleSubmit = async (values, setSubmitting) => {
		setSubmitting(true);

		try {
			const result = await props.signup(
				user_id,
				values.first_name,
				values.last_name,
				values.email,
				values.password,
				values.agreed,
				setSubmitting
			);
			if (result.success) props.next(values);
			else setresultError(true);
		} catch (e) {
			const error = await e;
			console.log(error.message);
		}
	};

	return (
		<SignupSection>
			<div className="reset-arrow">
				<BackButton
					label={"Back"}
					handleOnClick={() => navigate(-1)}
				/>
			</div>
			<Heading marginBottom="16px">
				<span>let's create your account</span>
			</Heading>
			<p className="instructions">Create an account in 4 easy steps.</p>
			<Formik
				enableReinitialize
				initialValues={props.data}
				validationSchema={Yup.object({
					first_name: Yup.string()
						.trim()
						.matches(/^[a-zA-Z\s'-]+$/, "First name is not valid")
						.min(1, "First name should be at least 1 character")
						.max(50, "First name should not exceed 50 characters")
						.required("* This Field is required"),
					last_name: Yup.string()
						.trim()
						.matches(/^[a-zA-Z\s'-]+$/, "Last name is not valid")
						.min(1, "Last name should be at least 1 character")
						.max(50, "Last name should not exceed 50 characters")
						.required("* This Field is required"),
					email: Yup.string()
						.trim()
						.email("Email is not valid")
						.max(190, "Email should not exceed 190 characters")
						.required("* This Field is required"),
					password: Yup.string().when(["first_name", "last_name", "email"], {
						is: (first_name, last_name, email) =>
							first_name && last_name && email,
						then: Yup.string()
							.required("* This Field is required")
							.min(8, "Password must be at least 8 characters long")
							.max(64, "Password cannot exceed 64 characters")
							.matches(
								/^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).*$/,
								"Password must contain at least one special character"
							)
							.trim()
							.test(
								"password-validation",
								"Password must not contain first name, last name, or email",
								function (value) {
									const { first_name, last_name, email } = this.parent;
									const firstNameLower = first_name.toLowerCase();
									const lastNameLower = last_name.toLowerCase();
									const emailLower = email.toLowerCase();
									if (value) {
										const passwordLower = value.toLowerCase();
										return (
											(!passwordLower.includes(firstNameLower) &&
												!passwordLower.includes(lastNameLower) &&
												!passwordLower.includes(emailLower)) ||
											this.createError({
												path: "password",
												message:
													"Password must not contain first name, last name, or email",
											})
										);
									}
								}
							)
							.test(
								"strong password",
								"*The supplied password does not meet the requirements",
								(value) => {
									let validRules = passwordRules;
									if (value) {
										validRules = getPasswordStrength(value, passwordRules);
										setPasswordRules(validRules);
										return Object.values(validRules).every(
											(val) => val === true
										);
									} else {
										validRules.letter = false;
										validRules.number = false;
										validRules.capital = false;
										validRules.count = false;
										setPasswordRules(validRules);
										return false;
									}
								}
							),
					}),
					confirm_password: Yup.string()
						.oneOf(
							[Yup.ref("password"), null],
							"The passwords entered in the 'Password' and 'Confirm Password' fields do not match."
						)
						.required("* This Field is required"),
					agreed: Yup.boolean()
						.oneOf([true], "Please accept before continuing")
						.required(
							`To continue with the registration, you must agree to our Terms and Conditions and Privacy Policy. Please tick the respective checkbox to acknowledge your agreement. If you have any concerns or queries, feel free to contact us at support@procuracon.com.au`
						),
				})}
				onSubmit={(values, { setSubmitting, setStatus }) => {
					setStatus(false);
					handleSubmit(values, setSubmitting);
				}}
			>
				{(props) => {
					const { setStatus, setFieldValue, isSubmitting, status, errors } =
						props;
					const onSubmitValidationError = () => {
						setStatus(true);
						if (errors?.agreed) {
							setIsOpen(true);
						}
					};
					return (
						<Form onChange={() => setStatus(false)}>
							<div className="row">
								<TextInput
									label="FIRST NAME"
									testId="signup-first-name"
									required
									placeholder="First Name"
									name="first_name"
									type="text"
									className="col xl-6 text-mr"
									setFieldValue={setFieldValue}
									guideLine={tooltips.first_name}
								/>
								<TextInput
									label="LAST NAME"
									testId="signup-last-name"
									required
									placeholder="Last Name"
									name="last_name"
									type="text"
									className="col xl-6 text-mr"
									setFieldValue={setFieldValue}
									guideLine={tooltips.last_name}
								/>
								<TextInput
									label="EMAIL ADDRESS"
									testId="signup-email"
									required
									placeholder="Email Address"
									name="email"
									type="email"
									className="col xl-12 text-mr"
									setFieldValue={setFieldValue}
									disabled={!!email}
									guideLine={tooltips.email}
								/>
								<TextInput
									data-tip
									data-for="pass_validation"
									testId="signup-password"
									label="PASSWORD"
									required
									placeholder="Password"
									name="password"
									type="password"
									className="col xl-12 text-mr"
									setFieldValue={setFieldValue}
									guideLine={tooltips.password}
									icon
								/>
								<PasswordValidator
									dataId={"pass_validation"}
									passwordRules={passwordRules}
								/>
								<TextInput
									label="CONFIRM PASSWORD"
									testId="signup-confirm-password"
									required
									placeholder="Confirm Password"
									name="confirm_password"
									type="password"
									className="col xl-12 text-mr"
									setFieldValue={setFieldValue}
								/>
								<BottomLinks className="col xl-12">
									<Checkbox2
										name="agreed"
										testId="signup-accept-t&cs"
										value="true"
										labelComponent={<CustomLabel />}
										noWarning
									/>
								</BottomLinks>
								<div className="col xl-12">
									{status && (
										<FlashError
											heading={"Required Field"}
											text={
												errors.agreed ||
												"There are incomplete required fields. Please complete them."
											}
											margin={"15px 0px 0px 0px"}
										/>
									)}
									{resultError && (
										<FlashError
											heading={"User already exists!"}
											text={
												<>
													Contact{" "}
													<a href="mailto:support@procuracon.com.au">
														support@procuracon.com.au
													</a>{" "}
													for assistance.
												</>
											}
											margin={"15px 0px 0px 0px"}
										/>
									)}
								</div>
								<div className="col xl-12">
									<Submit
										type="submit"
										testId="signup-continue"
										widthExpand
										marginTop={"56px"}
										isSubmitting={isSubmitting}
										text="Continue"
										submittingText="Sending..."
									/>
								</div>
							</div>
							<SignupTermsModal
								isModalOpen={isOpen}
								handleClose={() => setIsOpen(false)}
								handleOnAccept={() => {
									setFieldValue("agreed", true);
									setIsOpen(false);
								}}
							/>
							<OnSubmitValidationError callback={onSubmitValidationError} />
						</Form>
					);
				}}
			</Formik>
		</SignupSection>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.signup_details,
		state: state,
	};
};

export const SignUpForm = connect(mapStateToProps, { signup, logoutGuestUser })(
	SignUpFormComponent
);
