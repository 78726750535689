import React from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { useField } from "formik";
import { IMaskMixin } from "react-imask";
import _get from "lodash.get";
import ReactTooltip from "react-tooltip";
import { TextInputStyles, FormLabel } from "../TextInput";
import { ReactComponent as InfoIcon } from "../../../icons/info.svg";
import { Error } from "../Error";
import LabelForm from "components/RFQ/LabelForm/LabelForm";

// re-use style component
const StyledInput = styled.input`
	${TextInputStyles}
	${(props) =>
		props.error &&
		`
        border: 1px solid ${props.theme.colors.danger};
    `}
`;

const CurrencyStyled = IMaskMixin(({ inputRef, ...props }) => (
	<StyledInput
		{...props}
		ref={inputRef}
	/>
));

export const Currency = ({ label, required, ...props }) => {
	//eslint-disable-next-line
	const [field, meta] = useField(props);
	const { setFieldValue } = useFormikContext();

	return (
		<div className={`relative ${props.className}`}>
			{label && (
				<FormLabel htmlFor={props.id || props.name}>
					<LabelForm noMargin>{label}</LabelForm>
					{required && <span>*</span>}
					{props.guideLine && (
						<InfoIcon
							data-tip
							data-for={props.name}
						/>
					)}
					<ReactTooltip
						className="guide"
						id={props.name}
						place="top"
						arrowColor="transparent"
					>
						{props.guideLine}
					</ReactTooltip>
				</FormLabel>
			)}

			<CurrencyStyled
				radix="."
				error={meta.error && meta.touched}
				mask={Number}
				unmask={true}
				value={
					_get(props.values, props.name)
						? _get(props.values, props.name).toString()
						: ""
				}
				data-testid="cprofile-project-value"

				thousandsSeparator=","
				onAccept={(value) => {
					setFieldValue(props.name, value);
				}}
			/>

			<Error meta={meta} />
		</div>
	);
};
