import { Flex } from "@chakra-ui/react";
import { getListCompanyData } from "actions/RFQ/Supplier/rfqActions";
import { DateInput } from "components/DateInput";
import FilterButton from "components/FilterButton/FilterButton";
import { Location } from "components/FormInputs/Location";
import { Heading } from "components/Heading";
import { FileUpload } from "components/RFQ/FileUpload";
import { FieldForm } from "components/RFQ/Input";
import { RFQContext } from "context/RFQContext";
import { useField } from "formik";
import {
	CorporateDocument,
	KeyPersonnel,
	KeyPersonnelSkeleton,
	ProjectCard,
	ProjectCardSkeleton,
} from "pages/RFQ/RFQ/CreateRFQ/PreviewRFQ/Questionnaire/PreviewSubmissionComponent";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cn } from "utils/helpers";
import FilterDrawer from "../CustomFilterDrawer/FilterDrawer";
import { MultipleCheckbox } from "../MultipleCheckbox";
import SearchBar from "../SearchBar/SearchBar";
import { TextAreaQuestionnaire } from "./_components/TextAreaQuestionnaire";
import QuestionTypeTableBidder from "./QuestionTypeTableBidder";
import { AcceptedFileTypesForRFx } from "utils/rfxLargeFileUploadHelper";

export const SectionComponent = ({
	className,
	sectionTitle,
	description,
	fieldValue,
	rfqSubmission = false,
	...props
}) => {
	return (
		<>
			{rfqSubmission ? (
				<div className="space-y-2 border-b border-fg-senary-300 py-6">
					<Heading className="text-4xl !text-royal-blue">
						{sectionTitle}
					</Heading>
					<p className="text-lg text-tertiary-600">{description}</p>
				</div>
			) : (
				<div
					className={cn("mt-6 min-h-14 border-b", className)}
					{...props}
				>
					<h1 className="text-xl font-bold">{sectionTitle}</h1>
					<p>{description}</p>
				</div>
			)}
		</>
	);
};

export const SingleChoiceQuestionnaire = ({
	className,
	description,
	question,
	options,
	required,
	fieldValue,
	...props
}) => {
	const [, { error }] = useField(props);

	return (
		<div
			className={cn(className)}
			{...props}
		>
			<h1 className="text-lg font-semibold text-secondary-700">
				{question}
				<span
					className={cn(
						"font-normal text-tertiary-600",
						error && props?.touchedField && "font-semibold text-red-600"
					)}
				>
					{required && <span className="text-red-600">{`*`}</span>}
					{required ? " (Required)" : " (Optional)"}
				</span>
			</h1>
			{props?.rfqSubmission && (
				<p className="text-sm text-tertiary-600">(Select one option only)</p>
			)}
			<div>
				<FieldForm
					type="radio"
					className="my-4 w-full"
					options={fieldValue.map((item, i) => ({
						label: item,
						value: i.toString(),
					}))}
					name={props?.name || "questionnaire"}
					border
					disabled={props?.disabled}
				/>
			</div>
		</div>
	);
};
export const MultipleChoiceQuestionnaire = ({
	className,
	description,
	question,
	required,
	options,
	fieldValue,
	...props
}) => {
	const [, { error }] = useField(props);

	return (
		<div className={cn(!props?.rfqSubmission && "space-y-2", className)} data-testid={`header-${props?.name}`}>
			<h1 className="text-lg font-semibold text-secondary-700">
				{question}
				<span
					className={cn(
						"font-normal text-tertiary-600",
						error && props?.touchedField && "font-semibold text-red-600"
					)}
				>
					{required && <span className="text-red-600">{`*`}</span>}
					{required ? " (Required)" : " (Optional)"}
				</span>
			</h1>
			{props?.rfqSubmission && (
				<p className="text-sm text-tertiary-600">(Select one or more option)</p>
			)}
			<div>
				<MultipleCheckbox
					name={props?.name || "questionnaire"}
					checkboxes={fieldValue.map((item, i) => ({
						label: item,
						value: i.toString(),
					}))}
					className="my-4 w-full"
					noAttribute
					border
					disabled={props?.disabled}
					{...props}
				/>
			</div>
		</div>
	);
};
export const DropdownQuestionnaire = ({
	className,
	question,
	options,
	required,
	fieldValue,
	...props
}) => {
	const [, { error }] = useField(props);

	return (
		<div className={cn(className)}>
			<h1 className="text-lg font-semibold text-secondary-700" data-testid={`header-${props?.name}`}>
				{question}
				<span
					className={cn(
						"font-normal text-tertiary-600",
						error && props?.touchedField && "font-semibold text-red-600"
					)}
				>
					{required && <span className="text-red-600">{`*`}</span>}
					{required ? " (Required)" : " (Optional)"}
				</span>
			</h1>
			{props?.rfqSubmission && (
				<p className="text-sm text-tertiary-600">
					Select one option from dropdown
				</p>
			)}
			<div>
				<FieldForm
					type="select"
					options={fieldValue.map((item, i) => ({
						label: item,
						value: i.toString(),
					}))}
					className="my-4 w-full"
					required={required}
					name={props.name}
					disabled={props?.disabled}
				/>
			</div>
		</div>
	);
};
export const DateSelectionQuestionnaire = ({
	className,
	question,
	required,
	fieldValue,
	...props
}) => {
	const [, { error }] = useField(props);

	return (
		<div className={cn(className)}>
			<h1 className="text-lg font-semibold text-secondary-700">
				{question}
				<span
					className={cn(
						"font-normal text-tertiary-600",
						error && props?.touchedField && "font-semibold text-red-600"
					)}
				>
					{required && <span className="text-red-600">{`*`}</span>}
					{required ? " (Required)" : " (Optional)"}
				</span>
			</h1>
			{props?.rfqSubmission && (
				<p className="text-sm text-tertiary-600">Single-date selection</p>
			)}
			<div>
				<DateInput
					icon={true}
					className="!font-regular flex-3 my-4 !border-fg-disabled_subtle"
					required={required}
					applyFormated={"YYYY-MM-DD"}
					{...props}
				/>
			</div>
		</div>
	);
};
export const AddressFinderQuestionnaire = ({
	className,
	question,
	required,
	fieldValue,
	...props
}) => {
	const [, { error }] = useField(props);

	return (
		<div className={cn(className)}>
			<h1 className="text-lg font-semibold text-secondary-700">
				{question}
				<span
					className={cn(
						"font-normal text-tertiary-600",
						error && props?.touchedField && "font-semibold text-red-600"
					)}
				>
					{required && <span className="text-red-600">{`*`}</span>}
					{required ? " (Required)" : " (Optional)"}
				</span>
			</h1>
			{props?.rfqSubmission && (
				<p className="text-sm text-tertiary-600">Address selection</p>
			)}
			<div>
				<Location
					errorBottom={"-3px"}
					rfq
					className="relative my-4"
					required={required}
					rfqIcon="address-finder-icon"
					applyValue
					isErrorValue={error && props?.touchedField}
					{...props}
					inputTestId={`input-${props?.name}`}
					dropdownTestId={`location_auto_complete`}
				/>
			</div>
		</div>
	);
};
const SmartFieldQuestionnaire = ({
	className,
	question,
	values,
	required,
	fieldValue,
	smartField,
	...props
}) => {
	const isCorporateDocument = smartField === "Corporate Documents";
	const [listData, setListData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [filteringList, setFilteringList] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState([]);
	const [, meta, helper] = useField(props);
	const { value, error, touched } = meta;
	const { setValue, setTouched } = helper;
	const companyId = useSelector((state) => state?.search?.activeCompany?.id);

	const isSelected = (listValue) =>
		Array.isArray(value) && value.length > 0
			? value?.includes(listValue)
			: false;

	const selectHandler = (listValue) => {
		setTouched(true);

		isSelected(listValue)
			? setValue(value.filter((val) => val !== listValue))
			: setValue([...value, listValue]);
	};

	const [openDrawer, setOpenDrawer] = useState(false);

	const smartFieldInfo = () => {
		switch (smartField) {
			case "Project Experience":
				return {
					description: "Please choose the projects you would like to showcase.",
					placeholder: "Search project or client",
				};
			case "Key Personnel":
				return {
					description:
						"Please choose key personnel that might work on this project.",
					placeholder: "Search name, position or project",
				};
			case "Corporate Documents":
				return {
					description:
						"Please choose the corporate documents that you would like to share.",
					placeholder: "Search documents",
				};
			default:
				return null;
		}
	};

	const renderSmartField = (values) => {
		switch (smartField) {
			case "Project Experience":
				return (
					<ProjectCard
						companyId={companyId}
						values={values}
						rfqSubmission={true}
						selected={isSelected(values?.id)}
						selectHandler={selectHandler}
						isPreview={props?.disabled}
					/>
				);
			case "Key Personnel":
				return (
					<KeyPersonnel
						companyId={companyId}
						values={values}
						rfqSubmission={true}
						selected={isSelected(values?.id)}
						selectHandler={selectHandler}
						isPreview={props?.disabled}
					/>
				);
			default:
				return null;
		}
	};

	const filterSmartFieldOptionForSubmittedRFQ = (smartFieldData) => {
		if (props?.disabled) {
			return smartFieldData.filter((data) => value.includes(data.id));
		}

		return smartFieldData;
	};

	useEffect(() => {
		const params = {
			search: search,
			filters: selectedFilter,
		};
		switch (smartField) {
			case "Project Experience":
				setIsLoading(true);
				getListCompanyData("project-experience", params).then((res) => {
					setListData(
						filterSmartFieldOptionForSubmittedRFQ(res?.project_experience)
					);
					setFilteringList(res?.filters);
					setIsLoading(false);
				});
				break;
			case "Key Personnel":
				setIsLoading(true);
				getListCompanyData("key-personnel", params).then((res) => {
					setListData(
						filterSmartFieldOptionForSubmittedRFQ(res?.key_personnels)
					);
					setFilteringList(res?.filters);
					setIsLoading(false);
				});
				break;
			case "Corporate Documents":
				setIsLoading(true);
				getListCompanyData("corporate-document", params).then((res) => {
					setListData(
						filterSmartFieldOptionForSubmittedRFQ(res?.corporate_document)
					);
					setIsLoading(false);
				});
				break;
			default:
				return null;
		}
		//eslint-disable-next-line
	}, [search, selectedFilter]);

	return (
		<div
			className={cn(
				`${error && touched && "rounded p-2 shadow-md ring-1 ring-red-500 ring-offset-2"} ${className}`
			)}
			{...props}
		>
			<Flex className="items-center justify-between">
				<div>
					<h1 className="text-lg font-semibold text-secondary-700">
						{question}
						<span>
							{required && <span className="text-red-600">{`*`}</span>}
							{required ? " (Required)" : " (Optional)"}
						</span>
					</h1>
					<p className="text-sm text-tertiary-600">
						{smartFieldInfo().description}
					</p>
					{error && touched && <span className="text-red-600">{error}</span>}
				</div>
				<Flex className="items-center gap-2">
					<SearchBar
						setState={setSearch}
						placeholder={smartFieldInfo().placeholder}
						className="min-w-[360px]"
					/>
					{!isCorporateDocument && (
						<FilterButton
							onClick={() => setOpenDrawer(true)}
							filters={selectedFilter}
						/>
					)}
				</Flex>
			</Flex>
			<div>
				<Flex className="overflow-x-auto py-6 pl-1">
					{isCorporateDocument ? (
						<CorporateDocument
							values={listData}
							selectHandler={selectHandler}
							selected={isSelected}
							isLoading={isLoading}
							companyId={companyId}
							isPreview={props?.disabled}
						/>
					) : isLoading ? (
						[...Array(3)].map((_, index) =>
							smartField === "Project Experience" ? (
								<ProjectCardSkeleton key={index} />
							) : (
								<KeyPersonnelSkeleton key={index} />
							)
						)
					) : (
						listData?.length > 0 &&
						Array.isArray(listData) &&
						listData.map((list) => {
							return renderSmartField(list);
						})
					)}
					{!isCorporateDocument && (
						<FilterDrawer
							isOpen={openDrawer}
							onClose={() => setOpenDrawer(false)}
							filterOptions={filteringList}
							filters={selectedFilter}
							setFilters={setSelectedFilter}
						/>
					)}
				</Flex>
			</div>
		</div>
	);
};

export const FileInputQuestionnaire = ({
	className,
	question,
	required,
	fieldValue,
	singleFile,
	isCorporateDocument,
	...props
}) => {
	const [, { error }] = useField(props);
	const { rfqId } = useContext(RFQContext);

	return (
		<div
			className={cn(className)}
			{...props}
		>
			<h1 className="text-lg font-semibold text-secondary-700">
				{question}
				<span
					className={cn(
						"font-normal text-tertiary-600",
						error && props?.touchedField && "font-semibold text-red-600"
					)}
				>
					{required && <span className="text-red-600">{`*`}</span>}
					{required ? " (Required)" : " (Optional)"}
				</span>
			</h1>
			<div>
				<FileUpload
					type="file"
					className="my-4 w-full"
					types={AcceptedFileTypesForRFx}
					defaultFileName="Document"
					isDisabled={true}
					maxFileSize={100}
					singleFile={singleFile}
					multiple={isCorporateDocument}
					fileExtraData={{
						model: "rfq_returnable_schedule_questionnaire_file_response",
						question_id: Number(props?.name?.match(/\d+/g)), //abstract question id from question field name ( question_answer.question-question_id)
						rfq_id: rfqId,
					}}
					{...props}
					testId={`input-${props?.name}`}
				/>
			</div>
		</div>
	);
};
export const renderQuestion = (
	questionProps,
	index,
	hasDocuments,
	hasResources,
	hasPasProjects,
	disabled
) => {
	const { ...question } = questionProps;
	const { question: val, description, ...questionGuest } = questionProps;

	switch (question.type) {
		case "short":
			return (
				<TextAreaQuestionnaire
					key={index}
					{...question}
				/>
			);
		case "long":
			return (
				<TextAreaQuestionnaire
					key={index}
					{...question}
				/>
			);
		case "singleChoice":
			return (
				<SingleChoiceQuestionnaire
					key={index}
					{...question}
				/>
			);
		case "multipleChoice":
			return (
				<MultipleChoiceQuestionnaire
					key={index}
					{...question}
				/>
			);
		case "dropdown":
			return (
				<DropdownQuestionnaire
					key={index}
					{...question}
				/>
			);
		case "date":
			return (
				<DateSelectionQuestionnaire
					key={index}
					{...question}
				/>
			);
		case "address":
			return (
				<AddressFinderQuestionnaire
					key={index}
					{...question}
				/>
			);
		case "projectexp":
			return question?.isGuest || !hasPasProjects ? (
				<TextAreaQuestionnaire
					description="Maximum of 4500 characters."
					question={question?.question}
					{...questionGuest}
					typeQuestion="4500"
				/>
			) : (
				<SmartFieldQuestionnaire
					key={index}
					smartField="Project Experience"
					{...question}
				/>
			);
		case "keypersonel":
			return question?.isGuest || !hasResources ? (
				<TextAreaQuestionnaire
					description="Maximum of 4500 characters."
					question={question?.question}
					{...questionGuest}
					typeQuestion="4500"
				/>
			) : (
				<SmartFieldQuestionnaire
					key={index}
					smartField="Key Personnel"
					{...question}
				/>
			);
		case "corporatedoc":
			return question?.isGuest || !hasDocuments ? (
				<FileInputQuestionnaire
					question={question?.question}
					{...questionGuest}
					singleFile={!question?.isGuest}
					isCorporateDocument
				/>
			) : (
				<SmartFieldQuestionnaire
					key={index}
					smartField="Corporate Documents"
					{...question}
				/>
			);
		case "file":
			return (
				<FileInputQuestionnaire
					key={index}
					{...question}
					singleFile={false}
				/>
			);
		case "section":
			return (
				<SectionComponent
					key={index}
					{...question}
				/>
			);
		case "table":
			return (
				<QuestionTypeTableBidder
					key={index}
					tableQuestions={question?.fieldValue}
					{...question}
				/>
			);
		default:
			return null;
	}
};
