import React from "react";
import { DEFAULT_IMAGE_AVATAR } from "utils/constants";
import { cn } from "utils/helpers";

type ProfileAvatarProps = {
	imagePath: string;
	className?: string;
	defaultImage?: string;
	handleClick: () => void;
};

export const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
	imagePath,
	className,
	defaultImage,
}) => {
	const defaultImageFor = {
		user: "/img/user-01.svg",
		company: DEFAULT_IMAGE_AVATAR,
	};
	const imageSrc = imagePath ? imagePath : defaultImageFor[defaultImage];

	return (
		<div
			className={cn(
				`align-center flex justify-center overflow-hidden rounded-full bg-white`,
				className
			)}
		>
			<img
				src={imageSrc}
				className={`h-full w-full object-contain p-2`}
				alt=""
			/>
		</div>
	);
};
