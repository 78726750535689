import React from "react";
import { ReactComponent as DocumentIcon } from "../../icons/document.svg";
import { ReactComponent as ExpiredArrow } from "../../icons/arrow-expired.svg";
import { ReactComponent as ExpiredArrow30 } from "../../icons/arrow-expired30.svg";
import { ReactComponent as Check } from "../../icons/small-check.svg";
import { DocumentItemStyled } from "./styled";
import {
	isDateExpired,
	isDateWithin30Days,
	shortenDigits,
} from "../../utils/helpers";
import moment from "moment";

export const DocumentItem = ({
	document,
	title,
	children,
	noIcon,
	check,
	current_company,
	openDocumentModal,
	hideExpireDate,
	...props
}) => {
	const cross = props.cross;
	let documentName =
		(props.editable || document.display === "public") && document.storage_path
			? document.name
			: title
				? title
				: document.name;
	let documentNameArr = documentName.split(" ");
	let lastWord = documentNameArr[documentNameArr.length - 1];

	const isUnderInsuranceCerts = () => {
		return document.category?.parent_id === 4 || document.category?.id === 4;
	};

	if (
		isUnderInsuranceCerts() &&
		document.display === "public" &&
		lastWord.includes("$") === false
	) {
		documentName =
			documentName + " $" + shortenDigits(document.extra_info.value);
	} else if (
		document.display === "private" &&
		documentNameArr.length > 1 &&
		documentNameArr[documentNameArr.length - 2].includes("$") === true
	) {
		documentNameArr.splice(documentNameArr.length - 2, 1);
		documentName = documentNameArr.toString().replaceAll(",", " ");
	}

	// const messageExpired = () => {
	//   let message;
	//   switch (props?.parentTitle) {
	//     case "Insurance Certificates":
	//       message = "Insurance Certificates";
	//       break;
	//     default: message = "Document";
	//   }
	//   return message
	// }

	const ExpireComp = ({ text, isExpired, document }) => {
		console.log(document?.name, isExpired);

		return (
			<>
				<div>{text}</div>
				{window.location.href.includes("company-profile/documents") && (
					<div style={{ flex: 1 }}>
						<div>
							<button
								style={{ flex: 1 }}
								onClick={() => {
									openDocumentModal(true, document);
								}}
								className={`flex items-center justify-center rounded-lg border-[1px] ${isExpired ? 'border-[#B42318] text-["#B42318"] hover:bg-[#B42318]' : "border-[#B54708] text-[#B54708] hover:bg-[#B54708]"} cursor-pointer gap-2 px-4 py-2 hover:text-white`}
							>
								<span>Upload new document</span>
								{isExpired ? <ExpiredArrow /> : <ExpiredArrow30 />}
							</button>
						</div>
					</div>
				)}
			</>
		);
	};

	const ShowExpireComp = ({ documentDate, document }) => {
		return (
			<div style={{ marginLeft: "10px" }}>
				{documentDate && (
					<>
						{isDateWithin30Days(documentDate) ? (
							<div className="flex min-h-[30px] w-full flex-col gap-2 rounded-lg px-4 text-[12px] font-[500] text-[#B54708]" data-testid="expire-soon">
								<ExpireComp
									text="Expires within 30 days"
									isExpired={false}
									document={document}
								/>
							</div>
						) : (
							<>
								{isDateExpired(documentDate) && (
									<div className="flex min-h-[30px] w-full flex-col gap-2 rounded-lg px-4 text-[12px] font-[500] text-[#B42318]" data-testid="expired" >
										<ExpireComp
											text="Document expired"
											isExpired={true}
											document={document}
										/>
									</div>
								)}
							</>
						)}
					</>
				)}
			</div>
		);
	};

	return (
		<DocumentItemStyled {...props}>
			{!noIcon && <DocumentIcon />}
			{/* {document?.isExpireSoon && 
      <div className={`alert-icon alert-doc-${document.id}`}>
        <ExpiredIcon data-tip data-for={`expire-${document.id}`} />
        <ReactTooltip
            className="guide"
            id={`expire-${document.id}`}
            place="top"
            arrowColor="transparent"
          >
            {messageExpired()} is Expire soon
        </ReactTooltip>
      </div>} */}

			{document && (
				<>
					{(props.editable ||
						document.display === "public" ||
						document?.approved_companies?.includes(current_company?.id)) &&
					document.storage_path ? (
						<a
							href={document.storage_path}
							target="_blank"
							rel="noopener noreferrer"
							className="document__title"
							data-testid={`document-${document.id}`}
						>
							{documentName} {check ? <Check /> : cross ? <>&mdash;</> : ""}
						</a>
					) : (
						<p className="document__title">
							{documentName}{" "}
							{check ? (
								<div className="ml-1 mt-1">
									<Check />
								</div>
							) : cross ? (
								<>&mdash;</>
							) : (
								""
							)}{" "}
						</p>
					)}
					{children}

					{document.category?.parent_id === 4 && !hideExpireDate && (
						<ShowExpireComp
							documentDate={moment(document?.extra_info?.expiry_date).format(
								"YYYY-MM-DD"
							)}
							document={document}
						/>
					)}
				</>
			)}
		</DocumentItemStyled>
	);
};
