import React from "react";
import { Formik, Form } from "formik";
import styled from "styled-components";

import { FileUpload } from "../FormInputs/FileUpload";
import { Submit } from "../FormInputs/Submit";
import { FormHeading } from "../FormHeading";

export const ResourceProjectFormStyled = styled.div`
	background-color: white;
	padding: ${(props) => props.theme.space.sm_2};
`;

export const ResumeForm = ({
	document,
	onSubmit,
	isSubmitting,
	initialValues,
	values,
	title,
	index,
	onSave,
	...props
}) => {

	let testId = props?.testId;

	return (
		<ResourceProjectFormStyled>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={onSubmit}
			>
				{(props) => {
					const { values, isSubmitting, setFieldValue } = props;
					return (
						<Form>
							<FormHeading text={title || "Upload Resume"} />
							<div className="row" data-testid={testId}>
								<FileUpload
									label="File"
									required="true"
									values={values}
									name="resume_file"
									setFieldValue={setFieldValue}
									className="col xl-12"
									multi={false}
									acceptType="application/pdf"
								/>
							</div>

							<Submit
								type="submit"
								isSubmitting={isSubmitting}
								text="Continue"
								submittingText="Processing..."
								testId={`submit-${testId}`}
							/>
						</Form>
					);
				}}
			</Formik>
		</ResourceProjectFormStyled>
	);
};
