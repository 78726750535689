import React, { useState } from "react";
import { Link } from "@reach/router";
import styled from "styled-components";

import { ProjectLinkStyled, VisibilityButton } from "./styled";
import { Button } from "../Button";

import { ProjectDetailCard } from "components/ProjectCard";
import { Modal } from "../Modal";
import { ReactComponent as HideIcon } from "../../icons/invisible.svg";
import { ReactComponent as ShowIcon } from "../../icons/visible.svg";
import { TooltipLabel } from "components/MatchesLabel";
import { ReactComponent as StarIcon } from "../../icons/star-icon.svg";

import { connect } from "react-redux";

export const Image = styled.img`
	align-self: center;
	width: 70%;
	height: 64px;
	border-radius: ${(props) => props.theme.radii.md};
	object-fit: ${(props) => (props.isPlaceholder ? "contain" : "cover")};
	background-color: ${(props) =>
		props.isPlaceholder ? "#e9ecf1" : "transparent"};
`;

export const ProjectLinkComponent = ({
	data,
	isObj,
	handleAddProjectClick,
	handleRemoveProjectClick,
	onVisibiltyToggle,
	groupId,
	index,
	disableAddButton,
	isAdding,
	addProjectIndexes,
	isLoading,
	profileTypeId,
	handlePinProject,
	orderReq,
	...props
}) => {
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const { disabledTest } = props;

	const openModal = () => {
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	data = isObj ? data[1] : data;

	const testIfDisabled = () => {
		if (disabledTest) {
			return disabledTest(data.id);
		} else {
			return false;
		}
	};

	const isFeatured = (order, id) => {
		if (orderReq()) {
			if (id < 2) return true;
		} else if (!orderReq()) {
			if (order <= 2 && order !== null) return true;
		}
		return false;
	};

	return (
		<ProjectLinkStyled
			className={`project-link ${props.className}`}
			extraButton={handleAddProjectClick}
			ref={props.reff}
			{...props.dragProps}
		>
			<div className="project-link__cell project-link__cell--img">
				<Image
					src={
						data.images.length
							? data.images[0]
							: process.env.PUBLIC_URL + "/img/placeholder-sm.jpg"
					}
					isPlaceholder={!data.images.length}
					className="project-link__img"
					style={{ height: "55px", width: "100%" }}
					alt=""
				/>
			</div>

			<div className="project-link__cell project-link__cell--title">
				<div className="project-link__cell-inner">
					<h4 className="project-link__title">
						{data.name}{" "}
						{data.status === "draft" && (
							<span style={{ color: "gray" }}> - (Draft)</span>
						)}
					</h4>
				</div>
			</div>

			<div className="project-link__cell project-link__cell--spec">
				<div className="project-link__cell-inner">
					<p>{data.sector && data.sector.name} </p>
				</div>
			</div>

			<div className="project-link__cell project-link__cell--spec">
				<div className="project-link__cell-inner">
					{profileTypeId === 3 ? (
						<>
							<p>
								{data.trade_value === null
									? "Confidential/NA"
									: `$${data.trade_value}`}
							</p>
						</>
					) : (
						<>
							{isObj ? (
								<p>{data.value === 0 ? "Confidential/NA" : `$${data.value}`}</p>
							) : (
								<p>
									{data.value === 0
										? "Confidential/NA"
										: `$${data.value_humanized}`}
								</p>
							)}
						</>
					)}
				</div>
			</div>

			<div className="project-link__cell project-link__cell--spec project-link__cell--spec-year">
				<div className="project-link__cell-inner">
					<p>{data.year_completed}</p>
				</div>
			</div>

			<div className="project-link__cell project-link__cell--spec">
				<div className="project-link__cell-inner">
					{profileTypeId === 3 ? (
						<>
							{data.trades ? (
								<>
									{data.trades?.slice(0, 3).map((trade) => {
										return <p key={trade.id}>{trade.name}</p>;
									})}
									{data.trades?.length >= 4 && (
										<TooltipLabel label={data.trades?.slice(3)}>
											<p style={{ textDecoration: "underline" }}>
												{" "}
												and {data.trades?.length - 3} more
											</p>
										</TooltipLabel>
									)}
								</>
							) : (
								<></>
							)}
						</>
					) : (
						<>
							{data.procurement_methods && (
								<>
									{data.procurement_methods.map((method) => (
										<p key={method.id}>{method.name}</p>
									))}
								</>
							)}
						</>
					)}
				</div>
			</div>

			{props.edit && (
				<div className="project-link__cell project-link__cell--spec">
					<div className="project-link__cell-inner">
						<StarIcon
							data-testid="featured-projects"
							className={`${isFeatured(data.order, index) ? "animate-pop" : "star-icon"}`}
							width={25}
							height={25}
							style={{ margin: "3px 0 0 20px", cursor: "pointer" }}
							onClick={() => handlePinProject(index)}
						/>
					</div>
				</div>
			)}

			{props.edit && (
				<div className="project-link__cell project-link__cell--spec">
					<div className="project-link__cell-inner" >
						<VisibilityButton
							data-testid="button-publish"
							onClick={() => onVisibiltyToggle(data.id, data.status)}
							title={`Click to ${
								data.status === "published" ? "unpublish" : "publish"
							}`}
						>
							{data.status === "published" ? <ShowIcon /> : <HideIcon />}
						</VisibilityButton>
					</div>
				</div>
			)}

			<div className="project-link__cell">
				<>
					{props.edit ? (
						<Button
							id={data.name}
							as={Link}
							to={`./edit-project/${data.id}`}
							btntype="secondary"
							size="xs"
							className="!p-2"
						>
							Edit Project
						</Button>
					) : (
						<Button
							btntype="secondary"
							size="xs"
							className="!p-2"
							onClick={openModal}
						>
							View Project
						</Button>
					)}

					{handleAddProjectClick &&
						(testIfDisabled(data.id) ? (
							<Button
								btntype="secondary"
								size="sm"
								disabledStyle={isLoading}
								className="!p-2"
								onClick={() => handleRemoveProjectClick(data, index)}
							>
								Remove Project
							</Button>
						) : (
							<Button
								btntype="secondary"
								size="sm"
								className="!p-2"
								disabledStyle={
									testIfDisabled(data.id) ||
									addProjectIndexes?.length === 0 ||
									isLoading === true
								}
								disablePointer={
									addProjectIndexes?.length === 0 || isLoading === true
								}
								onClick={() => handleAddProjectClick(data, index)}
							>
								Add project
							</Button>
						))}
				</>
			</div>

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				width="50%"
				className="w-1/2 max-w-[1000px]"
				scroll
				shouldCloseOnOverlayClick
				innerClassName={"!max-h-[90vh]"}
			>
				<ProjectDetailCard data={data} />
			</Modal>
		</ProjectLinkStyled>
	);
};

ProjectLinkComponent.defaultProps = {
	name: "Project Name",
};

const mapStateToProps = (state, ownProps) => {
	return {
		isAdding: state.search.addingProjectCompare[ownProps.data.id],
		addProjectIndexes: state.search.addProjectIndex,
		isLoading: state.search.isAddingProject,
		profileTypeId: state.search.activeCompany.profile_type.id,
	};
};

export const ProjectLink = connect(mapStateToProps, {})(ProjectLinkComponent);
