import { navigate } from "@reach/router";
import { Button } from "components/RFQ/Button";
import React, { useEffect, useState } from "react";
import tw, { styled, css } from "twin.macro";
import { TabHeader } from "components/TabHeader";
import { UtilityButton } from "components/RFQ/UtilityButton";
import {
	Card,
	Flex,
	Grid,
	SkeletonText,
	useDisclosure,
} from "@chakra-ui/react";
import { Heading } from "components/Heading";
import StatusBadge from "components/RFQ/StatusBadge/StatusBadge";
import { AsyncImage } from "loadable-image";
import { Icon } from "assets/icons/Icon";
import NotFoundDatas from "components/RFQ/NotFoundDatas/NotFoundDatas";
import { SupplierList } from "./SupplierList";
import { useDispatch } from "react-redux";
import {
	getProjectById,
	setProjectFavorite,
	getFavoriteProjects,
} from "actions/RFQ/projectsAction";
import { NotFound } from "routes/NotFound";
import { SkeletonBase } from "components/Skeleton";
import { AddSupplierListDrawer } from "components/Pages/ProjectDetail/AddSupplierListDrawer";
import { BackButton } from "components/RFQ/BackButton";
import { isImageBroken } from "utils/helpers";
import { DEFAULT_IMAGE_AVATAR, DEFAULT_IMAGE_BANNER } from "utils/constants";
import { connect } from "react-redux";
import ProjectArchiveModal from "components/Pages/Projects/ProjectArchiveModal/ProjectArchiveModal";
import CreateRFQOverview from "pages/RFQ/RFQ/CreateRFQ/Overview/CreateRFQOverview";

const ProjectDetailContainer = styled.div(() => [
	tw`relative overflow-auto`,
	css`
		.buttons-container {
			gap: 8px;
		}
		.container-content {
			padding-right: 2px;
			padding-left: 2px;
		}
		.img-card {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		.project-description ul {
			list-style-type: disc;
			margin-left: 20px;
		}

		.project-description ol {
			list-style-type: decimal;
			margin-left: 20px;
		}

		.project-description li {
			margin-bottom: 5px;
		}
	`,
]);

const ProjectDetail = ({
	id,
	archive,
	favorites,
	getFavoriteProjects,
	isLoadingFavorites,
	...props
}) => {
	const dispatch = useDispatch();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isOpenArchiveModal, setIsOpenArchiveModal] = useState();
	const [isCreateRFxDrawerOpen, setIsCreateRFxDrawerOpen] = useState(false);
	const [projectData, setProjectData] = useState(null);
	const [bannerImage, setBannerImage] = useState(
		projectData?.backgroundImagePath
	);
	const [avatarImage, setAvatarImage] = useState(projectData?.logoPath);
	const [supplierLists, setSupplierLists] = useState([]);
	const [supplierListsWithRFQs, setSupplierListsWithRFQs] = useState([]);
	const [isNotFound, setIsNotFound] = useState(false);
	const [isArchive, setIsArchive] = useState(
		projectData?.status.toUpperCase() === "ARCHIVED"
	);
	const [isFavorite, setIsFavorite] = useState(
		favorites.includes(parseInt(id)) || false
	);
	const [loadProject, setLoadProject] = useState(false);

	useEffect(() => {
		if (!isLoadingFavorites) {
			getFavoriteProjects();
		}
		if (!projectData) {
			setLoadProject(true);
			getProjectById(id)
				.then((data) => {
					if (data) {
						setProjectData(data);
						setLoadProject(false);
					} else {
						setIsNotFound(true);
					}
				})
				.catch((err) => {
					setIsNotFound(true);
					setLoadProject(false);
				});
		}
		if (projectData) {
			setIsArchive(projectData?.status.toUpperCase() === "ARCHIVED");
			setSupplierListsWithRFQs(projectData?.rfqs || []);
			setSupplierLists(projectData?.supplierList || []);
			setBannerImage(projectData?.backgroundImagePath);
			setAvatarImage(projectData?.logoPath);
		}
		if (isLoadingFavorites === "idle") {
			setIsFavorite(favorites.includes(parseInt(id)) || false);
		}
	}, [
		projectData,
		id,
		favorites,
		isLoadingFavorites,
		getFavoriteProjects,
		dispatch,
	]);

	const handleFavourite = async (e) => {
		e.stopPropagation(); // Prevent event from double calling

		// Optimistically update UI
		setIsFavorite(!isFavorite);

		try {
			await dispatch(
				setProjectFavorite({
					project_ids: [id],
					isRemove: isFavorite,
				})
			);
			// Refresh favorite projects list
			getFavoriteProjects();
		} catch (error) {
			setIsFavorite(isFavorite);
			console.error("Failed to update favorite status:", error);
		}
	};

	const handleOnProjectStatusChange = () => {
		navigate("../../projects");
	};

	const checkImages = async () => {
		let isBannerBroken = await isImageBroken(projectData?.backgroundImagePath);
		let isAvatarBroken = await isImageBroken(projectData?.logoPath);
		if (isBannerBroken) {
			setBannerImage(DEFAULT_IMAGE_BANNER);
		}
		if (isAvatarBroken) {
			setAvatarImage(DEFAULT_IMAGE_AVATAR);
		}
	};

	useEffect(() => {
		checkImages();
		//eslint-disable-next-line
	}, [projectData]);

	isNotFound && <NotFound />;

	return isNotFound ? (
		<NotFound />
	) : (
		<ProjectDetailContainer {...props}>
			<TabHeader
				icon={
					<BackButton
						handleBackButtonClick={() => {
							if (window.history.state === null) {
								return navigate("../");
							}
							return navigate(-1);
						}}
					/>
				}
			>
				<Flex gap={4}>
					{!loadProject ? (
						<>
							{!isArchive && (
								<>
									<UtilityButton
										text="Archive Project"
										icon="archive"
										onClick={() => setIsOpenArchiveModal(true)}
										data-testid="archive-project-button"
									/>
									<Button onClick={() => navigate(`../edit-project/${id}`)} data-testid="update-project-button">
										<div className="flex items-center gap-1">
											<Icon icon="edit-icon" />
											<span>Update Project/Asset</span>
										</div>
									</Button>
								</>
							)}
						</>
					) : (
						<>
							<SkeletonBase
								width="120px"
								height="40px"
							/>
							<SkeletonBase
								width="120px"
								height="40px"
							/>
						</>
					)}
				</Flex>
			</TabHeader>
			<div className="container-content">
				<Card className="card-shadow relative mb-8 overflow-hidden rounded-2xl bg-gray-50">
					<Grid
						className="grid-cols-12 rounded-md bg-white"
						justifyContent={"space-between"}
					>
						<div className="col-span-7 p-8">
							<div
								className="flex items-center"
								gap={6}
							>
								<div
									className={`mr-6 mt-2 h-12 w-12 content-center rounded-full bg-cover bg-center bg-no-repeat text-center font-medium text-utility-gray-700 drop-shadow-md`}
									style={{ backgroundImage: `url(${avatarImage})` }}
								></div>
								{!loadProject ? (
									<Heading className="text-5xl !text-royal-blue">
										{projectData?.name}
									</Heading>
								) : (
									<SkeletonBase
										height="48px"
										width="70%"
									/>
								)}
							</div>
							<div className="mt-6">
								{!loadProject ? (
									<span
										className="project-description font-roboto font-normal text-primary-700"
										style={{ fontSize: "18px" }}
										dangerouslySetInnerHTML={{
											__html: projectData?.description,
										}}
									/>
								) : (
									<SkeletonText
										noOfLines={4}
										width="90%"
										skeletonHeight="20px"
										startColor="lightgray"
									/>
								)}
							</div>
							<div
								id="project-info"
								className="mt-12 border-t pt-6"
							>
								{!loadProject && projectData ? (
									<Flex className="flex-col gap-4 text-base font-semibold">
										<Flex gap={4}>
											<p
												className="min-w-36 font-roboto font-normal text-secondary-500"
												style={{ lineHeight: "24px", fontSize: "16px" }}
											>
												Project Status
											</p>
											<StatusBadge status={projectData?.status} />
										</Flex>
										<Flex gap={4}>
											<p
												className="min-w-36 font-roboto font-normal text-secondary-500"
												style={{ lineHeight: "24px", fontSize: "16px" }}
											>
												Project Sector
											</p>
											<p
												className="font-roboto font-semibold text-primary-900"
												style={{ fontSize: "16px", lineHeight: "24px" }}
											>
												{projectData &&
													projectData.sector
														.map((sector) => sector.name)
														.join(", ")}
											</p>
										</Flex>
										<Flex gap={4}>
											<p
												className="min-w-36 font-roboto font-normal text-secondary-500"
												style={{ lineHeight: "24px", fontSize: "16px" }}
											>
												Project Lead
											</p>
											<p
												className="font-roboto font-semibold text-primary-900"
												style={{ fontSize: "16px", lineHeight: "24px" }}
											>
												{`${projectData?.projectLead?.firstName} ${projectData?.projectLead?.lastName}`}
											</p>
										</Flex>
										<Flex gap={4}>
											<p
												className="min-w-36 font-roboto font-normal text-secondary-500"
												style={{ lineHeight: "24px", fontSize: "16px" }}
											>
												Project Location
											</p>
											<p
												className="font-roboto font-semibold text-primary-900"
												style={{ fontSize: "16px", lineHeight: "24px" }}
											>
												{projectData?.location}
											</p>
										</Flex>
									</Flex>
								) : (
									<Flex className="flex-col gap-4 text-base font-semibold">
										<Flex gap={4}>
											<SkeletonBase />
											<SkeletonBase width="280px" />
										</Flex>
										<Flex gap={4}>
											<SkeletonBase />
											<SkeletonBase width="280px" />
										</Flex>
										<Flex gap={4}>
											<SkeletonBase />
											<SkeletonBase width="280px" />
										</Flex>
										<Flex gap={4}>
											<SkeletonBase />
											<SkeletonBase width="280px" />
										</Flex>
									</Flex>
								)}
							</div>
						</div>
						<div className="relative col-span-5">
							<AsyncImage
								src={bannerImage}
								alt="banner-card"
								className="img-card"
								loader={
									<img
										src={DEFAULT_IMAGE_BANNER}
										alt="banner-card"
										className="img-card"
										style={{ filter: "blur(10px)" }}
									/>
								}
							/>
							<Icon
								icon="favorite"
								style={{
									color: isFavorite ? "#FFCD00" : "transparent",
									alignSelf: "center",
									cursor: "pointer",
									filter: "drop-shadow(0px 0px 1px #EAECF0)",
								}}
								className="absolute right-5 top-5 z-10"
								onClick={handleFavourite}
							/>
						</div>
					</Grid>
				</Card>
				<div id="card-supplier-list">
					<Card className="card-shadow relative mb-8 rounded-md !border !border-gray-50 bg-white p-1">
						<div
							id="card-container-supplier"
							className="bg-white"
						>
							<div
								id="card-header-supplier"
								className="flex items-center justify-between border-b px-6 py-5"
							>
								<div
									gap={2}
									className="flex items-center"
								>
									<h1 className="text-lg font-semibold text-black">
										Supplier List(s) attached to this Project
									</h1>
								</div>
								<div className="flex gap-2">
									<Button
										btntype={"secondary"}
										onClick={() => setIsCreateRFxDrawerOpen(true)}
									>
										Create New RFx
									</Button>
									<Button
										btntype={"primary"}
										onClick={onOpen}
										data-testid="attach-supplier-list-button"
									>
										<div className="flex items-center gap-1">
											<Icon
												icon="plus"
												style={{
													width: "14px",
													height: "14px",
													color: "#fff",
													fontWeight: "bold",
												}}
											/>
											<span>Attach Supplier List</span>
										</div>
									</Button>
								</div>
							</div>
							<div
								id="supplier-content"
								className="rounded-md"
							>
								{supplierListsWithRFQs.length > 0 ? (
									<div>
										<SupplierList
											data={supplierListsWithRFQs}
											project={projectData}
											setSupplierLists={setSupplierLists}
										/>
									</div>
								) : (
									<div className={`place-content-center`}>
										<NotFoundDatas
											text={"No Supplier Lists found"}
											description={
												"To get started, attach relevant Suppliers from your Supplier List to this project/asset"
											}
											className="absolute left-2/4 -translate-x-2/4"
										>
											<Button
												btntype={"primary"}
												style={{ width: "max-content" }}
												onClick={onOpen}
											>
												<Flex
													gap={2}
													align={"center"}
												>
													<div className="flex h-5 w-5 items-center justify-center">
														<Icon
															icon="plus"
															style={{
																width: "12px",
																height: "12px",
																color: "#fff",
																fontWeight: "bold",
															}}
														/>
													</div>
													Attach Supplier List
												</Flex>
											</Button>
										</NotFoundDatas>
									</div>
								)}
							</div>
						</div>
					</Card>
					{isOpen && (
						<AddSupplierListDrawer
							projectData={projectData}
							defaultLists={supplierLists}
							isOpenContainer={isOpen}
							onCloseContainer={onClose}
							projectId={projectData?.id}
							setSupplierLists={setSupplierLists}
							setSupplierListsWithRFQs={setSupplierListsWithRFQs}
						/>
					)}
					{isCreateRFxDrawerOpen && (
						<CreateRFQOverview
							isOpen={isCreateRFxDrawerOpen}
							onClose={() => setIsCreateRFxDrawerOpen(false)}
							projectId={projectData.id}
						/>
					)}
				</div>
			</div>
			<ProjectArchiveModal
				isModalOpen={isOpenArchiveModal}
				setIsModalOpen={setIsOpenArchiveModal}
				isArchived={isArchive}
				onSuccessHandler={handleOnProjectStatusChange}
				projectData={projectData}
			/>
		</ProjectDetailContainer>
	);
};

const mapStateToProps = (state) => {
	return {
		favorites: state.rfq.projects.favorites.project_ids,
		isLoadingFavorites: state.rfq.projects.favorites.loading,
	};
};

export default connect(mapStateToProps, { getFavoriteProjects })(ProjectDetail);
