import React from "react";
import { Flex, Skeleton, SkeletonCircle } from "@chakra-ui/react";
import NotFoundDatas from "components/RFQ/NotFoundDatas/NotFoundDatas";
import { RenderOnViewportEntry } from "components/RFQ/RenderOnViewportEntry/RenderOnViewportEnter";

import tw, { styled, css } from "twin.macro";
import ProjectTile from "components/Pages/Projects/ProjectTile/ProjectTile";
import { Link } from "@reach/router";
import { Button } from "components/RFQ/Button";
import { Icon } from "assets/icons/Icon";

const ProjectCard = React.lazy(
	() => import("components/RFQ/ProjectCard/ProjectCard")
);

const GridViewStyled = styled.div(() => [
	tw`w-full flex-wrap -mx-2 rounded-md`,
]);

const ProjectCardSkeletonStyled = styled.div(() => [
	tw`relative mb-8`,
	css`
		#card-content {
			padding: 24px;
			height: 100%;
			max-height: 314px;
			position: relative;
			display: grid;
			align-content: end;
			gap: 6px;
			bottom: 4px;
			${tw`bg-gray-50`}
		}
		#header-card {
			height: 104px;
			position: relative;
			${tw`rounded-t-md border-t-8 bg-gray-100`}
		}
	`,
]);

const ProjectCardSkeleton = () => {
	return (
		<ProjectCardSkeletonStyled>
			<div id="header-card">
				<SkeletonCircle
					startColor="#d2d2d2"
					size="48px"
					className="absolute -bottom-4 left-6 z-10"
				/>
			</div>
			<div id="card-content">
				<Skeleton
					height="40px"
					mb="24px"
					startColor="#d2d2d2"
				/>
				<Skeleton
					height="30px"
					mb="4px"
					startColor="#d2d2d2"
				/>
				<span className="block w-full border-b border-gray-200" />
				<Skeleton
					height="30px"
					mb="4px"
					startColor="#d2d2d2"
				/>
				<span className="block w-full border-b border-gray-200" />
				<Skeleton
					height="30px"
					mb="4px"
					startColor="#d2d2d2"
				/>
				<span className="block w-full border-b border-gray-200" />
				<Skeleton
					height="30px"
					mb="4px"
					startColor="#d2d2d2"
				/>
			</div>
		</ProjectCardSkeletonStyled>
	);
};

const GridViewProjects = ({
	data,
	isLoading,
	currentTab,
	currPage,
	isSidebarCollapsed,
}) => {
	return (
		<GridViewStyled>
			<RenderOnViewportEntry
				loadingComponent={<ProjectTile />}
				className={`grid w-full py-8 ${
					isSidebarCollapsed
						? "grid-cols-2 lg:grid-cols-4 xlg:grid-cols-3 xxlg:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6"
						: "grid-cols-1 lg:grid-cols-3 xlg:grid-cols-3 xxlg:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6"
				} gap-6`}
			>
				{isLoading && currPage === 1 && !data ? (
					<>
						{[...Array(6)].map(() => (
							<ProjectCardSkeleton />
						))}
					</>
				) : (
					<>
						{data?.length > 0 ? (
							<>
								{data.map((project, index) => {
									return (
										<ProjectCard
											key={index}
											title={project.name}
											id={project.id}
											banner={project.backgroundImagePath}
											logo={project.logoPath}
											status={project.status}
											lead={`${project.projectLead?.firstName} ${project.projectLead?.lastName}`}
											supplierlists={project.numberSupplierList}
											rfqs={project.numberRfq}
											isFavorite={project.isFavorite}
											currentTab={currentTab}
											sortTestId={`project-card-${index}-${project.id}`}
										/>
									);
								})}
								{isLoading &&
									currPage >= 1 &&
									data &&
									[...Array(6)].map(() => <ProjectCardSkeleton />)}
							</>
						) : (
							<div className="absolute left-2/4 -translate-x-2/4">
								<NotFoundDatas
									text={`No Projects/Assets Found`}
									description={"To get started, create a Project/Asset."}
								>
									<Link to={`new-project`}>
										<Button style={{ maxHeight: "48px" }} data-testid="create-new-project">
											<Flex className="items-center gap-[6px]">
												<div className="flex h-5 w-5 items-center justify-center">
													<Icon
														icon="plus"
														style={{
															width: "12px",
															height: "12px",
															color: "#fff",
															fontWeight: "bold",
														}}
													/>
												</div>
												<div className="font-roboto text-base font-semibold capitalize">
													Create Project/Asset
												</div>
											</Flex>
										</Button>
									</Link>
								</NotFoundDatas>
							</div>
						)}
					</>
				)}
			</RenderOnViewportEntry>
		</GridViewStyled>
	);
};

export default GridViewProjects;
